

import  React, { useEffect, useState }  from  "react";

import NotificationsIcon from '@mui/icons-material/Notifications';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HomeIcon from '@mui/icons-material/Home';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import SendIcon from '@mui/icons-material/Send';

import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { ScanCodeOutline } from 'antd-mobile-icons'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HandymanIcon from '@mui/icons-material/Handyman';

// import { QrReader } from 'react-qr-reader';
// import { BrowserQRCodeReader } from '@zxing/library';
// import Webcam from 'react-webcam';

import { BottomNavigation } from  "reactjs-bottom-navigation";
import Badge from '@mui/material/Badge';


import { useNavigate } from 'react-router-dom';





import axios from 'axios'
import Settings from '@mui/icons-material/Settings';

import {BarcodeOutlined  } from '@ant-design/icons';
import User_profile from "../User_profile";
import Wallet from "./Wallet";
import Settions from "./Settions";
import Server_product from "./Server_product";
import Ball_notifictions from "./Ball_notifictions";
import { FloatButton } from "antd";






function  Bottom_navbar() {













// const [first, setfirst] = useState(second)




// const [result, setResult] = useState('');

//   useEffect(() => {
//     const startScanner = async () => {
//       const codeReader = new BrowserQRCodeReader();
//       const videoElement = document.getElementById('qr-video');

//       try {
//         const stream = await navigator.mediaDevices.getUserMedia({ video: true });
//         videoElement.srcObject = stream;

//         codeReader.decodeFromVideoDevice(undefined, videoElement, (result, error) => {
//           if (result) {
//             setResult(result.getText());
//           }
//           if (error) {
//             console.error(error);
//           }
//         });
//       } catch (error) {
//         console.error('Error starting the camera:', error);
//       }
//     };

//     startScanner();

//     return () => {
//       // Cleanup: stop the scanner when the component is unmounted
//       const videoElement = document.getElementById('qr-video');
//       const stream = videoElement.srcObject;
//       if (stream) {
//         const tracks = stream.getTracks();
//         tracks.forEach((track) => track.stop());
//       }
//     };
//   }, []);




const [getkeyvalue, setquntity] = useState(4)

const get_page_user=()=>{

switch (getkeyvalue) {
  case 1: return   <Settions  /> ;
  case 2: return <Server_product />;
  case 3: return <Ball_notifictions />;
  case 4: return <Wallet /> ;
  case 5: return <Wallet /> ;
    



  default: return <h>error page </h>;
 
}

}







useEffect(() => {


	// setInterval(() => {
	// 	userOrdrsall()
	// }, 3000);
	


}, [])



const [allorders, setDAtaall] = useState([])

const [lengths, setLenght] = useState('')

// const userOrdrsall=()=>{

// 	let id=localStorage.getItem('id');

// 	axios.get(`https://court.my-shopings.com/public/api/order_card_get/${id}`)
// 	.then(res=>{
// 	console.log(res.data);
// 	setDAtaall(res.data.data);
// console.log('====================================');
// console.log('this bottom menu alllllllllllllllll');
// console.log(res.data.data.length);
// 	setLenght(res.data.data.length);
		
// 	}).catch(error=>{
// 		console.error(error);
		
// 	})

	
// }





	const usenativs =useNavigate();

	const setalldataHome=(id)=>{
	
		// usenativs('/');
	}

	const setalldataHome2=(id)=>{
	
		// usenativs('/user-profles');
	}


const [keus, setValues] = useState(0)





const Cardsshow=(id)=>{

    setquntity(id);




if (id == 1) {


console.log('====================================');
console.log('settions');
console.log('====================================');



}else if(id==2){

	console.log('====================================');
	console.log('troe now');
	console.log('====================================');

}

else if(id==3){

    console.log('====================================');
	console.log('troe now 333');
	console.log('====================================');

}else if(id==4){

    console.log('====================================');
	console.log('troe now 44');
	console.log('====================================');

}

else{

	usenativs('/')
}






	
}








   
const  bottomNavItems = [
	{
		title:  "back",
        backgroundColor:'black',
		onClick: ({ id }) =>  Cardsshow(id),
		icon: <Badge badgeContent={0} max={99} color="secondary">    <HomeIcon />  </Badge>, // just for example
		activeIcon:  <Badge badgeContent={0} max={99} color="secondary">     <HomeIcon color="#fff" />  </Badge>
	},  

    {
		title:  "settings",
        backgroundColor:'black',
		onClick: ({ id }) =>  Cardsshow(id),
		icon: <Badge badgeContent={0} max={99}   color="secondary">  <Settings />  </Badge> , // just for example
		activeIcon:  <Badge badgeContent={0} max={99} color="secondary">   <AccountCircleIcon color="#fff" /> </Badge> 
	},

	


    {
		
   
		onClick: ({ id }) =>  Cardsshow(id),
		icon: 
        <HandymanIcon color=''  style={{fontSize:34}}  className=' text-danger' />
        
        , // just for example
		activeIcon:    <HandymanIcon  className=' text-danger' style={{fontSize:34}}  color='red' />
    },


    {
		title:  "Notification",
   
		onClick: ({ id }) =>  Cardsshow(id),
		icon:  <Badge badgeContent={0} max={99} color="secondary">
  <NotificationsIcon />
        </Badge>, // just for example
		activeIcon:  <Badge badgeContent={0} max={99} color="secondary">  <NotificationsIcon color="#fff" /> </Badge>
	},





    {
		title:  "Wallate",
		onClick: ({ id }) =>  Cardsshow(id),
		icon:   <Badge badgeContent={keus} max={99} color="secondary"> <i class="fa fa-usd" aria-hidden="true"></i>  </Badge> , // just for example
		activeIcon: <Badge badgeContent={keus} max={99} color="secondary"> <i class="fa fa-usd" aria-hidden="true"></i> </Badge>,
        
	}


    // {
	// 	render: ({ isActive, id }) =>  isActive ? <strong>{id}</strong> : <span>{id}</span>,
	// },

	// items can have either title, icon or both or neither!
	
	
];

  

return (
<div>

{get_page_user()}





{/* <FloatButton
      shape="square"
      type="primary"
      
      style={{
        right: 24,
        marginBottom:30,
      }}
      icon={<VolunteerActivismIcon color="red" className='' /> }
    /> */}



	<BottomNavigation
backgroundColor={'blue'}
style={{background:'blue'}}
		items={bottomNavItems}
	selected={4}
		onItemClick={(item) =>  console.log(item)}
		activeBgColor="#5654"
     
		activeTextColor="#000"



    

        
	/>




</div>
);

}


export default Bottom_navbar
