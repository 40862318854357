




import React from 'react'
import Winnner from './Winnner';




function Winner_spin() {



 
        const segments = ["Happy", "Angry", "Sad", "Frustration", "Emptyness"];
        const segColors = [
          "#EE4040",
          "#F0CF50",
          "#815CD1",
          "#3DA5E0",
          "#FF9000",
        ];



        const onFinished = (winner) => {
         console.log(winner);
        };
  
        






  return (
<>



<div id="wheelCircle">
        <Winnner
          segments={segments}
          segColors={segColors}
          winningSegment=""
          onFinished={(winner) => onFinished(winner)}
          primaryColor="black"
          primaryColoraround="#ffffffb4"
          contrastColor="white"
          buttonText="Spin"
          isOnlyOnce={false}
          size={190}
          upDuration={50}
          downDuration={2000}
        />
      </div>


</>
  )
}

export default Winner_spin






