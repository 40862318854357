import  React,{useEffect,useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';



import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';

import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';


import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';














import { GiftFilled, GiftOutlined, UserOutlined, UploadOutlined } from '@ant-design/icons';


import  { ImageList, ImageListItem, ImageListItemBar, Menu, MenuItem } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Bottom_navbar from './Commponet/Menu/Bottom_navbar';
import { Badge, Switch, Input, Popover, Button, Avatar, FloatButton, Upload, message} from 'antd';







import Product_home_page from './Commponet/product_page/Product_home_page';
import { Link, Navigate, json, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Image } from '@mui/icons-material';







const { Search } = Input;

const drawerWidth = 240;

function stringAvatar(name: string) {
  return {
    
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}



interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

export default function ResponsiveDrawer(props: Props) {




  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (id) => {
 


      if(id=='Logout'){
      
      localStorage.removeItem('username');
      localStorage.removeItem('img');
      localStorage.removeItem('olds');
      localStorage.removeItem('uiqid');
      localStorage.removeItem('id');
      localStorage.removeItem('brand');
      localStorage.removeItem('catagoris');

      naigakdld('/Login');
      
      
     
      }
  
  
  
    
  };







  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);


  const handleUpload = () => {

  //   const formData = new FormData();
  //   fileList.forEach((file) => {
  //     formData.append('files[]', file);
  //   });
  //   setUploading(true);
  //   // You can use any AJAX library you like
  //   fetch('https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188', {
  //     method: 'POST',
  //     body: formData,
  //   })
  //     .then((res) => res.json())
  //     .then(() => {
  //       setFileList([]);
  //       message.success('upload successfully.');
  //     })
  //     .catch(() => {
  //       message.error('upload failed.');
  //     })
  //     .finally(() => {
  //       setUploading(false);
  //     });
  // };




  // const propsssss = {
  //   onRemove: (file) => {
  //     const index = fileList.indexOf(file);
  //     const newFileList = fileList.slice();
  //     newFileList.splice(index, 1);
  //     setFileList(newFileList);
  //   },
  //   beforeUpload: (file) => {
  //     setFileList([...fileList, file]);
  //     return false;
  //   },
  //   fileList,
  // };

  }




  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const [sturlimg, setImageUrl] = useState();

  console.log('url sturlimg url ract js ====================================');

  console.log('====================================');




  const propsssss = {
    name: 'file',
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {










        message.success(`${info.file.name} file uploaded successfully`);








      }
      if (info.file.status === 'done') {







        message.success(`${info.file.name} successfully`);

        getBase64(info.file.originFileObj, (url) => {
          // setLoading(false);
          setImageUrl(url);
        });




console.log('on upload file name or ====================================');
console.log('on upload file name or ====================================');
console.log('on upload file name or ====================================');
console.log('on upload file name or ====================================');
console.log('on upload file name or ====================================');




console.log(sturlimg);





console.log('====================================');









      } else if (info.file.status === 'error') {



        message.error(`${info.file.name} file upload failed.`);
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
















  };












  const content = (
   
      <>

     
     
        <MenuItem onClick={handleClose}>
          <ListItemIcon>

            <PersonAdd fontSize="small" />

          </ListItemIcon>
         <Link to={'/Register'}>  Add another account </Link>

        </MenuItem>
        <MenuItem onClick={()=>naigakdld('/user-profles')}>
          <ListItemIcon>
            {'৳'}
          </ListItemIcon>
          Balance
        </MenuItem>



        {/* <MenuItem onClick={()=>handleUpload()}>
        <Upload {...propsssss}>

          <ListItemIcon>
       
      
    
            <SwitchAccountIcon fontSize="small" />
          </ListItemIcon>
        
          profile change


          </Upload>
        </MenuItem> */}
       

        <Divider />




        <MenuItem onClick={()=>handleClose('Logout')}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>

</>
  
  );

  


const naigakdld=useNavigate();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const setItems=(id)=>{


    if(id=='Logout'){
      naigakdld('/Login');
    
    }



  }







  

  const container = window !== undefined ? () => window().document.body : undefined;



  // const itemData = [
  //   {
  //     img: 'https://images.unsplash.com/photo-1549388604-817d15aa0110',
  //     title: 'Bed',
  //   },
  //   {
  //     img: 'https://images.unsplash.com/photo-1525097487452-6278ff080c31',
  //     title: 'Books',
  //   },
  //   {
  //     img: 'https://images.unsplash.com/photo-1523413651479-597eb2da0ad6',
  //     title: 'Sink',
  //   },
  //   {
  //     img: 'https://images.unsplash.com/photo-1563298723-dcfebaa392e3',
  //     title: 'Kitchen',
  //   },
  //   {
  //     img: 'https://images.unsplash.com/photo-1588436706487-9d55d73a39e3',
  //     title: 'Blinds',
  //   },
  //   {
  //     img: 'https://images.unsplash.com/photo-1574180045827-681f8a1a9622',
  //     title: 'Chairs',
  //   },
  //   {
  //     img: 'https://images.unsplash.com/photo-1530731141654-5993c3016c77',
  //     title: 'Laptop',
  //   },
  //   {
  //     img: 'https://images.unsplash.com/photo-1481277542470-605612bd2d61',
  //     title: 'Doors',
  //   },
  //   {
  //     img: 'https://images.unsplash.com/photo-1517487881594-2787fef5ebf7',
  //     title: 'Coffee',
  //   },
  //   {
  //     img: 'https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee',
  //     title: 'Storage',
  //   },
  //   {
  //     img: 'https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62',
  //     title: 'Candle',
  //   },
  //   {
  //     img: 'https://images.unsplash.com/photo-1519710164239-da123dc03ef4',
  //     title: 'Coffee table',
  //   },
  // ];



  


  
  let ids=localStorage.getItem('olds');



let Urls=localStorage.getItem('img');


let names=localStorage.getItem('username');
let account=localStorage.getItem('uiqid');

const [oldnew, setNewold] = useState('new');


const oldnewchange=(id)=>{

  if(!id==true){
   localStorage.setItem('olds', 'new');
  }else{
    localStorage.setItem('olds', 'old');
  }


}




const fabStyle = {
  position: 'absolute',
  bottom: 16,
  right: 16,
};





const [lodings, setLogins] = useState(true)
const [productss, setDataproducts] = useState([])

useEffect(() => {


  localStorage.setItem('olds', 'new');





}, [])


useEffect(() => {



get_products();



}, [productss])

const get_products=()=>{

  
let ids=localStorage.getItem('olds');

axios.get(`https://api.my-shopings.com/products/${ids}`)
.then(res=>{
console.log('====================================');

setLogins(false);

console.log(res.data);

console.log('====================================');

setDataproducts(res.data);

}).catch(error=>{
  console.log(error);

})

}




// const Url=JSON.parse(localStorage.getItem('img'));



const [catagori, setCatagori2] = useState();

const setCatagoris =(catagoris)=>{

  setCatagori2(catagoris);

 localStorage.setItem('catagoris', catagoris);


}









let catas=localStorage.getItem('catagoris');






const drawer = (
  <div className='p-1'>
    
    <Divider />
    <List className='bg-dark text-white' >




    {productss.map((item) => (
<>



<Link to={`/catagory/${item.catagori}/${item.old}`} >
<ListItem key={2}  className='text-white'  onClick={()=>setItems('name')} disablePadding>
          <ListItemButton>
            <ListItemIcon>



            <InboxIcon  className='text-white'  />



              {/* {index % 4 === 0 ?  [<InboxIcon />]: <MailIcon />} */}
            </ListItemIcon>
            <ListItemText primary={item.catagori} />
          </ListItemButton>
        </ListItem>

</Link>

</>






    ))}




    


      {/* {['Profile', 'Winner', '', 'about us','Logout','Logout','Logout','Logout','Logout','Logout','Logout','Logout','Logout'].map((text, index) => (
        <ListItem key={text} onClick={()=>setItems(text)} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              {index % 4 === 0 ?  [<InboxIcon />]: <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItemButton>
        </ListItem>
      ))} */}

    </List>



  </div>
);




























const onSearch = (value, _e, info) =>{
  
  
  
  
  
  
  console.log(info?.source, value);


}





const natives=useNavigate();



const Changes=()=>{



}


















































const [searchQuery, setSearchQuery] = useState("");
const [filteredItems, setFilteredItems] = useState([]);

const itemList = [
  { name: "Apple", price: "$1.99" },
  { name: "Banana", price: "$0.99" },
  { name: "Orange", price: "$1.49" },
  { name: "Strawberry", price: "$2.49" },
  { name: "Grapes", price: "$3.99" },
  { name: "Watermelon", price: "$4.99" },
];


const handleInputChange = (event) => {
  const query = event.target.value;
  setSearchQuery(query);
  const filtered = productss.filter((item) =>
    item.name.toLowerCase().includes(query.toLowerCase())
  );
  setFilteredItems(filtered);
};

const handleSubmit = (event) => {
  event.preventDefault();
  console.log("Search query:", searchQuery);
  const filtered = productss.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  setFilteredItems(filtered);
};







  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />

          </IconButton>

          <Typography variant="p" noWrap component="div">

            <form onSubmit={handleSubmit}>

      

          <Search placeholder="input search text"    value={searchQuery} onChange={handleInputChange} onSearch={onSearch} enterButton />

</form>

        
          </Typography>

         


          <Typography variant="h6 mr-0 " value="right" style={{ position:'absolute', right:15, padding:5, top:0, }} className='p-1' noWrap component="div">







          <Popover content={content} className='' trigger="click">
    
  
{Urls ?


  <Avatar size={50} style={{ top:0, right:-13,}} src={`https://court.my-shopings.com/public/profile_users/${Urls}`} /> 
:


<Avatar style={{ top:5,backgroundColor: '#87d068' }} icon={<UserOutlined />} />

}

         
          
          </Popover>
          
          <p style={{position:'absolute',Top:'10'}}>   {names}  </p>
          <p style={{position:'absolute',Top:'10'}}>   {account}  </p>
         
          

       
          </Typography>






        </Toolbar>


        {/* <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked /> */}





     

      </AppBar>



      <Box
      className=''
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}



       




    
   
     

        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
       className=''
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
<div className='bg-dark text-white  p-2'>






<Switch  className='m-2'  onChange={oldnewchange}     checkedChildren="old"  unCheckedChildren="new"    />


<Typography paragraph>


<p className='p' style={{color:'aqua', fontSize:14}}>  <span> Name : </span>  {names} </p>
<span className='p' style={{color:'aqua', fontSize:10}}>  <span> Account No : </span>  {account}  </span>

       
       </Typography>



<div className='bg-dark'>

{drawer}



</div>
       
      


       </div>


        </Drawer>



        <Drawer
backgroundColor={'#000'}
        className='bg-dark'
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
           
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>























      <Box
      className='p-1'
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >








        <Toolbar />



{/* thi a end bra ta button  */}

{/* thi a end bra ta button  */}{/* thi a end bra ta button  */}{/* thi a end bra ta button  */}{/* thi a end bra ta button  */}{/* thi a end bra ta button  */}{/* thi a end bra ta button  */}{/* thi a end bra ta button  */}{/* thi a end bra ta button  */}





    
      {/* <form className="search-bar">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleInputChange}
        />
        <button type="submit">Search</button>
      </form> */}
     

{/* thi a end bra ta button  */}

{/* thi a end bra ta button  */}{/* thi a end bra ta button  */}{/* thi a end bra ta button  */}{/* thi a end bra ta button  */}{/* thi a end bra ta button  */}{/* thi a end bra ta button  */}{/* thi a end bra ta button  */}{/* thi a end bra ta button  */}














































        <ImageList cols={2}>


  
        {searchQuery === "" ? (
       






       productss.map((item) => (
        
        <Link to={`/catagory/${item.catagori}/${item.old}`} >
        <ImageListItem    
        

        onClick={()=>setCatagoris(item.catagori)}
        
        >
         {item.img ?
    

    <img
style={{height:'150px', width:'100%'}}
 srcSet={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
 src={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
 alt={item.tag}
 loading="lazy"
/>

  :

  <img
 


 style={{height:'150px', width:'100%'}}

 srcSet={item.imglink}
 src={item.imglink}
 alt={item.tag}
 loading="lazy"
/>

  }






          <ImageListItemBar
            title={item.catagori}
            subtitle={item.name}
            
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.model}`}
              >
              <p className='badge bd-dark' style={{fontSize:13}}>  {item.old}</p> 

              </IconButton>




             
            }


            
          />










        </ImageListItem>


        </Link>

        
      ))









        ) : filteredItems.length > 0 ? (
          


          filteredItems.map((item) => (
        
            <Link to={`/catagory/${item.catagori}/${item.old}`} >
            <ImageListItem    
            
    
            onClick={()=>setCatagoris(item.catagori)}
            
            >
             {item.img ?
        
    
        <img
    style={{height:'150px', width:'100%'}}
     srcSet={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
     src={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
     alt={item.tag}
     loading="lazy"
    />
    
      :
    
      <img
     
    
    
     style={{height:'150px', width:'100%'}}
    
     srcSet={item.imglink}
     src={item.imglink}
     alt={item.tag}
     loading="lazy"
    />
    
      }
    
    
    
    
    
    
              <ImageListItemBar
                title={item.catagori}
                subtitle={item.name}
                
                actionIcon={
                  <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    aria-label={`info about ${item.model}`}
                  >
                  <p className='badge bd-dark' style={{fontSize:13}}>  {item.old}</p> 
    
                  </IconButton>
    
    
    
    
                 
                }
    
    
                
              />
    
    
    
    
    
    
    
    
    
    
            </ImageListItem>
    
    
            </Link>
    
            
          ))
    







        ) : (
          <li>No matching items found</li>
        )}
    

    
    












    </ImageList>




<br/>
<br/>
<br/>

<Divider />
<Divider />
<Divider />




    {lodings==false  ?
        
        <>

   

        </>
        
        :
        
        <>


      {/* <CircularProgress color="secondary" />
      <CircularProgress color="success" />
      <CircularProgress color="inherit" /> */}

<br/>
<br/>
<br/>

      <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="secondary" />
      <LinearProgress color="success" />
      <LinearProgress color="inherit" />


      <LinearProgress color="secondary" />
      <LinearProgress color="success" />
      <LinearProgress color="inherit" />



      <LinearProgress color="secondary" />
      <LinearProgress color="success" />
      <LinearProgress color="inherit" />




      <LinearProgress color="secondary" />
      <LinearProgress color="success" />
      <LinearProgress color="inherit" />


      
      <LinearProgress color="secondary" />
      <LinearProgress color="success" />
      <LinearProgress color="inherit" />




      <LinearProgress color="secondary" />
      <LinearProgress color="success" />
      <LinearProgress color="inherit" />



      <LinearProgress color="secondary" />
      <LinearProgress color="success" />
      <LinearProgress color="inherit" />
    </Stack>





        </>
        
        }













        <Typography  paragraph>
       




        {/* <Product_home_page  /> */}







        </Typography>
  






<FloatButton     className='bg-danger  disabled'  href='https://lottery.my-shopings.com/' style={{background:'#01111df8', color:'aque'}} icon={<GiftOutlined  disabled={true}  />} style={{marginBottom:'30px'}} tooltip={<div>Winner Lottary</div>} />


       

        

  



<Bottom_navbar style={{color:'red', background:'blue'}} />









</Box>

    </Box>
  );
}