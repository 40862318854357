
// import Box from '@mui/material/Box';


// import Modal from '@mui/material/Modal';
// import { Carousel, Image, Form, Input, Space, Tooltip, Typography, Flex, Spin, Card} from 'antd'
// import { Button, TextareaAutosize } from '@mui/material';


// import laravelApi from '../../../Url/Urls';
// import Nodeapi from '../../../Url/Apinode';
// import { CheckCircle } from '@mui/icons-material';
// import { useNavigate } from 'react-router-dom';

// // const  {Option}  = Select;


// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   width:300,
//   transform: 'translate(-50%, -50%)',
//  color:'#fff',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };








// const contentStyle = {
//     height: '160px',
//     color: '#fff',
    
//     textAlign: 'center',
//     background: '#364d79',
//   };











// function Server_product() {


//     const [open, setOpen] = React.useState(false);
//     const handleOpen = () => setOpen(true);
//     const handleClose = () => setOpen(false);




//     const onFinish = (values) => {
//         console.log('Received values of form: ', values);
//       };




//       const [name_product, set_pro_name]=useState();
//       const [type, set_Type]=useState();
//       const [u_name, set_Name]=useState();
//       const [problem, setProblem]=useState();
//       const [phone, setPhone]=useState();
//       const [address, setAddress]=useState();
     

// const [loding, setLogins]=useState();
//       const Requestadd=()=>{

//         const data={
//           name:u_name,
//           phone:phone,
//           address:address,
//           id:localStorage.getItem('id'),
//           name_pro:name_product,
//           type:type,
//           problem:problem,
        
//         }

// setLogins(  
//    <Flex gap="small"  className='bg-dark p-3'>
// <Spin tip="Loading" className='bg-dark p-1' size="small">
  
// </Spin> 

// </Flex>
// );

// laravelApi.post('product_service_reques',data)
// .then(res =>{
//   // alert('re');

//   setInterval(() => {









     
//     setLogins(<CheckCircle className='p-2 text-success' style={{fontSize:40}} /> );


//   }, 4000);

//   setInterval(() => {


//     setOpen(false);
     
   
//     setLogins();

//   }, 8000);

// console.log('error  product_service_reques ====================================');
// console.log(res.data);
// console.log('====================================');

// })
// .catch(error =>{
//   // alert('error ', error);
//   console.log(error);
//   setLogins(<span className='text-danger'>error</span>);
// })
     
//       }
    
// useEffect(() => {
//   setInterval(() => {
//     getallvalue_count_waiting();

//   getallvalue();

//   getallvalue_Delevery();

//   getallvalue_confrim();

//   getallvalue_mess();

//   }, 2000);
// }, [])
// // count_waiting





// var id=localStorage.getItem('id');




// const [count_waiting, setC_count_waiting] = useState(0)
// const getallvalue_count_waiting=()=>{

//   Nodeapi.get(`getApiproduct-service-confrim/${id}`)

// .then(res =>{
//   count_waiting(res.data.length);
// }).catch(error=>{
//  console.log('====================================');
//  console.log('error error ');
//  console.log('====================================');
// })
// }




// const [count_pending_confimr, setCountproduct_confrim] = useState(0)
// const getallvalue_confrim=()=>{

//   Nodeapi.get('getApiproduct-service-confrim')

// .then(res =>{
//   setCountproduct_confrim(res.data.length);
// }).catch(error=>{
//  console.log('====================================');
//  console.log('error error ');
//  console.log('====================================');
// })
// }



// const [count__delevery, setC_delevery] = useState(0)
// const getallvalue_Delevery=()=>{

//   Nodeapi.get('getApiproduct-service-delevery')

// .then(res =>{
//   setC_delevery(res.data.length);
//   console.log('====================================');
//   console.log('delevery product ');
//   console.log('====================================');
// }).catch(error=>{
//   console.log(error,'====================================');
// })
// }





// const [count_Mess, setC_Mess] = useState(0)
// const getallvalue_mess=()=>{

//   Nodeapi.get('getApiproduct-service-mess')

// .then(res =>{
//   setC_Mess(res.data)
// }).catch(error=>{
//  console.log('====================================');
//  console.log('error  me ');
//  console.log('====================================');
// })
// }



// const [count_pending, setCountproduct] = useState(0)
// const getallvalue=()=>{

//   Nodeapi.get('getApiproduct-service-pending')

// .then(res =>{
//   setCountproduct(res.data.length)
// }).catch(error=>{
// console.log('====================================');
// console.log(error,'error');
// console.log('====================================');
// })
// }


//   return (
//     <div>



// <Carousel autoplay style={{position:'absolute',width:'100%', height:'100%',opacity:0.4,}}>
//     <div style={contentStyle}>

     

// <Image    style={{width:'100%'}} src={require('../../logo/mobile-repair-logo-icon-260nw-499104070.webp')}     />


     
//     </div>
//     <div style={contentStyle}>

//      <Image   style={{width:'100%'}}   src={require('../../logo/Screenshot_2.png')}     />
//     </div>
//     <div style={contentStyle}>
//     <Image    style={{width:'100%'}}  src={require('../../logo/download.jpeg')}     />
//     </div>
//     <div style={contentStyle}>
//     <Image   style={{width:'100%'}}   src={require('../../logo/img_2606.jpg')}     />
//     </div>














//     <div style={contentStyle}>
//     <Image   style={{width:'100%'}}   src={require('../../logo/istockphoto-840552836-612x612.jpg')}     />
//     </div>



//     <div style={contentStyle}>
//     <Image   style={{width:'100%'}}   src={require('../../logo/maxresdefault.jpg')}     />
//     </div>



//     <div style={contentStyle}>
//     <Image   style={{width:'100%'}}   src={require('../../logo/mobile-repair-logo-icon-260nw-499104070.webp')}     />
//     </div>
//     <div style={contentStyle}>
//     <Image   style={{width:'100%'}}   src={require('../../logo/Screenshot_1.png')}     />
//     </div>
//   </Carousel>



// <div className='container p-2'>



// <div className='' style={{height:'300px', width:'100%'}}>



// </div>

// <div className='' style={{margin:'auto'}}>




// <Button   onClick={handleOpen} className=' bg-dark btn m-2  p-1' style={{color:'aqua',zIndex:110,}}>
//          <p className='p'>
   
     
//      <Typography>

//      Product Service request

//      </Typography>
    
          
       

//          </p>
  
// </Button>





// <div className=''>



// <Button>  

// pending  {`(${count_pending} )`}
// </Button>


// <Button>
// Waiting  {`(${count_waiting} )`}
// </Button>


// <Button>
// complete {`(${count_pending_confimr} )`}
// </Button>


// </div>
// <div>

// <Card  onClick={()=>alert()} className='col-3 m-3 bg-dark text-white'> delevery {`(${count__delevery} )`} </Card>

// <br/>
// <br/>
// <br/>
// <br/>
// <br/>
// <br/>
// {/* <Button className='bg-info btn text-white m-3'> <span>55</span></Button>

// <Button className='bg-info btn text-white m-3'> <span>55</span></Button>

// <Button className='bg-info btn text-white m-3'> <span>55</span></Button>

// <Button className='bg-info btn text-white m-3'> <span>55</span></Button>

// <Button className='bg-info btn text-white m-3'> <span>55</span></Button> */}




// </div>





// </div>









// </div>














// {/* 
// <Modal
//         disablePortal
//         disableEnforceFocus
//         disableAutoFocus
//         open={modelopent}
//         aria-labelledby="server-modal-title"
//         aria-describedby="server-modal-description"
//         sx={{
//           display: 'flex',
//           p: 1,
//           alignItems: 'center',
//           justifyContent: 'center',
//         }}
//         container={() => rootRef.current}
//       >
//         <Box
//           sx={{
//             position: 'relative',
//             width: 400,
//             bgcolor: 'background.paper',
//             border: '2px solid #000',
//             boxShadow: (theme) => theme.shadows[5],
//             p: 4,
//           }}
//         >
//  <Box sx={{ maxWidth: 400 }}>
//       <Stepper activeStep={activeStep} orientation="vertical">
//         {steps.map((step, index) => (
//           <Step key={step.label}>
//             <StepLabel
//               optional={
//                 index === 2 ? (
//                   <Typography variant="caption">Last step</Typography>
//                 ) : null
//               }
//             >
//               {step.label}
//             </StepLabel>
//             <StepContent>
//               <Typography>{step.description}</Typography>
//               <Box sx={{ mb: 2 }}>
//                 <div>
//                   <Button
//                     variant="contained"
//                     onClick={handleNext}
//                     sx={{ mt: 1, mr: 1 }}
//                   >
//                     {index === steps.length - 1 ? 'Finish' : 'Continue'}
//                   </Button>
//                   <Button
//                     disabled={index === 0}
//                     onClick={handleBack}
//                     sx={{ mt: 1, mr: 1 }}
//                   >
//                     Back
//                   </Button>
//                 </div>
//               </Box>
//             </StepContent>
//           </Step>
//         ))}
//       </Stepper>
//       {activeStep === steps.length && (
//         <Paper square elevation={0} sx={{ p: 3 }}>
//           <Typography>All steps completed - you&apos;re finished</Typography>
//           <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
//             Reset
//           </Button>
//         </Paper>
//       )}
//     </Box>

//        </Box>
//       </Modal> */}
// {/* this model product server reques */}



// <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//          <Box
//          className={'bg-dark '}
//           sx={{
//             position: 'absolute',
//             top: '50%',
//             left: '50%',
//             width:400,
//             transform: 'translate(-50%, -50%)',
//            color:'#fff',
           
//             border: '2px solid #000',
//             boxShadow: 24,
//             p: 4,
//           }}
//         >
//  <Box sx={{ maxWidth: 400 }}>
          
// <h4>Product Service Request </h4>
//         <Form
//     name="complex-form"
//     onFinish={onFinish}
    
//     style={{
//       maxWidth: 600,
//     }}
//   >
//     <Form.Item label="Brand name ">
//       <Space>
//         <Form.Item
//           name="brand name "
//           noStyle
//           rules={[
//             {
//               required: true,
//               message: 'brand name is required',
//             },
//           ]}
//         >
//           <input
// name='name'
// className='form-control'
//           onChange={(e)=>set_pro_name(e.target.value)}
//             style={{
           
//             }}
//             placeholder="sansung, walton, nova, symphoney"
//           />
//         </Form.Item>
//         <Tooltip title="Useful information">
//           <Typography.Link href="#API">Need Help?</Typography.Link>
//         </Tooltip>
//       </Space>
//     </Form.Item>

   


//     <Form.Item label="select product">
//       <Space.Compact>
//         <Form.Item
//           name={['select product', 'province']}
//           noStyle
//           rules={[
//             {
//               required: true,
//               message: 'select product name is required',
//             },
//           ]}
//         >



//           <select className='select form-select'    onChange={(e)=>set_Type(e.target.value)}>

//             <option value={'mobile'}>mobile</option>
//             <option value={'freez'}>freez</option>
//             <option value={'pc'}>pc</option>
//             <option value={'crt'}>crt tv</option>
//             <option value={'LED'}>LED tv</option>
//             <option value={'gash-stove'}>Gash stove</option>
//             <option value={'rice-coker'}>Rice coker</option>
//             <option value={'more'}>more </option>
         
//           </select>



//         </Form.Item>












//         {/* <Form.Item
//           name={['address', 'street']}
//           noStyle
//           rules={[
//             {
//               required: true,
//               message: 'Street is required',
//             },
//           ]}
//         >
//           <Input
//             style={{
//               width: '50%',
//             }}
//             placeholder="Input street"
//           />
//         </Form.Item> */}

//       </Space.Compact>
//       <br/>
   
//     <br/>
 
    
//       <TextareaAutosize    onChange={(e)=>setProblem(e.target.value)}  className=' form-control' placeholder='type product problem 1. 2. 3. ' />



    
      


//       <br/>








// <Form.Item label="Full name"> 
//       <Form.Item
//           name=" name "
//           noStyle
//           rules={[
//             {
//               required: true,
//               message: ' name is required',
//             },
//           ]}
//         >
//           <input
// className='form-control'
// onChange={(e)=>set_Name(e.target.value)}

//             placeholder="name"
//           />
//         </Form.Item>
// </Form.Item>


// <Form.Item label="Phone number">
//         <Form.Item
//           name="phone number "
//           noStyle
//           rules={[
//             {
//               required: true,
//               message: 'number 01784.. is required',
//             },
//           ]}
//         >
//           <Input
//           onChange={(e)=>setPhone(e.target.value)}
//            style={{color:'black',Width:199, }}
//            className='form-control '
          
//             placeholder="phone number 01784.."
//           />
//         </Form.Item>
// </Form.Item>

        
//         <Form.Item className='text-white' color={'#fff'} label="Full Address">
//         <Form.Item
//           name="Full Address "
//           noStyle
//           rules={[
//             {
//               required: true,
//               message: 'Full Address is required',
//             },
//           ]}
//         >
//           <input


// onChange={(e)=>setAddress(e.target.value)}
            
//               style={{color:'black',Width:199, }}
       

//             className='form-control '
//             placeholder="Full Address"
//           />
//         </Form.Item>
//         </Form.Item>
        




//         </Form.Item>
 



//     <Form.Item label="" colon={false}>
//       <Button   className='bg-info text-white'  onClick={Requestadd}  htmlType="submit">
//         Submit {loding}
//       </Button>
//     </Form.Item>
//   </Form>



//   </Box>

//         </Box>
//       </Modal>





//     </div>
//   )
// }

// export default Server_product
import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';






import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';







import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';





import { CustomerServiceOutlined, PlusCircleOutlined, SettingOutlined } from '@ant-design/icons';



import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import EngineeringIcon from '@mui/icons-material/Engineering';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import MoreVertIcon from '@mui/icons-material/MoreVert';


import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Box from '@mui/material/Box';







import Modal from '@mui/material/Modal';
import { Carousel, Image, Form, Input, Space, Tooltip, Typography, Flex, Spin, Card, FloatButton} from 'antd'
import { Button, TextareaAutosize } from '@mui/material';


import laravelApi from '../../../Url/Urls';
import Nodeapi from '../../../Url/Apinode';
import { CheckCircle, Delete, Edit, FormatClear, Home } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

// const  {Option}  = Select;
const actions = [
  { icon: <FileCopyIcon />, name: 'Copy' },
  { icon: <SaveIcon />, name: 'Save' },
  { icon: <PrintIcon />, name: 'Print' },
  { icon: <ShareIcon />, name: 'Share' },
];




function TabPanel(props) {
  const { children, value, index, ...other } = props;












  
  

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };






























  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);




  const onFinish = (values) => {
      console.log('Received values of form: ', values);
    };





    useEffect(() => {

      
setInterval(() => {
  getAllgetapi();
}, 4000);
     



    }, [])


    const [name_product, set_pro_name]=useState();
    const [type, set_Type]=useState();
    const [u_name, set_Name]=useState();
    const [problem, setProblem]=useState();
    const [phone, setPhone]=useState();
    const [address, setAddress]=useState();
   

const [loding, setLogins]=useState();
    const Requestadd=()=>{

      const data={
        name:u_name,
        phone:phone,
        address:address,
        id:localStorage.getItem('id'),
        name_pro:name_product,
        type:type,
        problem:problem,
      
      }

setLogins(  
 <Flex gap="small"  className='bg-dark p-3'>
<Spin tip="Loading" className='bg-dark p-1' size="small">

</Spin> 

</Flex>
);

laravelApi.post('product_service_reques',data)
.then(res =>{
// alert('re');

setInterval(() => {









   
  setLogins(<CheckCircle className='p-2 text-success' style={{fontSize:40}} /> );


}, 4000);

setInterval(() => {


  setOpen(false);
   
 
  setLogins();

}, 8000);

console.log('error  product_service_reques ====================================');
console.log(res.data);
console.log('====================================');

})
.catch(error =>{
// alert('error ', error);
console.log(error);
setLogins(<span className='text-danger'>error</span>);
})
   
    }
  
useEffect(() => {
setInterval(() => {
  getallvalue_count_waiting();

getallvalue();

getallvalue_Delevery();

getallvalue_confrim();

getallvalue_mess();

}, 2000);
}, [])
// count_waiting





var id=localStorage.getItem('id');




const [count_waiting, setC_count_waiting] = useState(0)
const getallvalue_count_waiting=()=>{

Nodeapi.get(`getApiproduct-service-confrim/${id}`)



.then(res =>{
count_waiting(res.data.length);
}).catch(error=>{
console.log('====================================');
console.log('error error ');
console.log('====================================');
})
}




const [count_pending_confimr, setCountproduct_confrim] = useState(0)
const getallvalue_confrim=()=>{

Nodeapi.get('getApiproduct-service-confrim')

.then(res =>{
setCountproduct_confrim(res.data.length);
}).catch(error=>{
console.log('====================================');
console.log('error error ');
console.log('====================================');
})
}



const [count__delevery, setC_delevery] = useState(0)
const getallvalue_Delevery=()=>{

Nodeapi.get('getApiproduct-service-delevery')

.then(res =>{
setC_delevery(res.data.length);
console.log('====================================');
console.log('delevery product ');
console.log('====================================');
}).catch(error=>{
console.log(error,'====================================');
})
}





const [count_Mess, setC_Mess] = useState(0)
const getallvalue_mess=()=>{

Nodeapi.get('getApiproduct-service-mess')

.then(res =>{
setC_Mess(res.data)
}).catch(error=>{
console.log('====================================');
console.log('error  me ');
console.log('====================================');
})
}




const [count_pending, setCountproduct] = useState(0);

const [state_All_data, sets_New_service] = useState([])
const getallvalue=()=>{

Nodeapi.get('getApiproduct-service-pending')

.then(res =>{
setCountproduct(res.data.length);
sets_New_service(res.data);





}).catch(error=>{
console.log('====================================');
console.log(error,'error');
console.log('====================================');
})
}






const [nameee, setstate_Name] = useState('pending')
const [pendinge, setstate_pendig] = useState(0)
const [pendindata, setstate_Dingdata] = useState([])



const getAllgetapi=()=>{

  let id=localStorage.getItem('id');

 
  Nodeapi.get(`getApiproduct-service-delevery-pending-add-data/${id}/${nameee}`)

  .then(res =>{
    setstate_pendig(res.data.length);

    setstate_Dingdata(res.data);
  
  console.log(id,'gettttttttttttt_data_____________--====================================');
  console.log(res.data);
  console.log('777777777====================================');
  }).catch(error=>{
  console.log(error,'________________________________________________====================================');
  })
  }






  const formate_service=(id)=>{


    alert(id);
    
    }

    

    const edit_service=(id)=>{


      alert(id);
      
      }

      


const deleted_service=(id)=>{


  let data={
    id:id,
  }

  
  

Nodeapi.get(`getApiproduct-service-delevery-pending-deleted/${id}`)
.then(res=>{
  alert(res.data);
}).catch(error =>{
  alert('error deleted axios',error);
})




}






  






  return (

    <div className='container'>











    <Box sx={{ bgcolor: 'background.paper',  }}>
      <AppBar position="static">
        <Tabs
          value={value}
          scrollButtons
          allowScrollButtonsMobile
          
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
         
       
        >

          <Tab  style={{fontSize:10}}  icon={<Home  />} label="Home" {...a11yProps(0)} />
          <Tab style={{fontSize:10}}  icon={<AutorenewIcon />}  label="new request" {...a11yProps(1)} />
          <Tab style={{fontSize:10}} icon={<EngineeringIcon />} label="waiting" {...a11yProps(2)} />
         
          <Tab  style={{fontSize:10}} icon={<PublishedWithChangesIcon />} label="complete" {...a11yProps(3)} />
          <Tab style={{fontSize:10  }} icon={<CheckCircle />} label="delivery" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
   
        <TabPanel value={value} index={0} dir={theme.direction}>




  
        <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert variant="outlined" severity="success">

       



যে কোন পণ্য  সার্ভিস করতে  Request পাঠাতে পারেন ।




<Alert  variant="outlined" severity="success">
       
<h6> পণ্য গুলো সার্ভিস করতে পারবেন। নিম্নে নাম দেওয়া হলো   </h6>
<p className='text-danger'>




 ফ্রিজ, এলইডি টিভি, সিআরটি টিভি, এসি, রাইচ কোকার ,
বাড়ির ওয়ারিং ফান ও লাইট, মোবাইল ফোন, গ্যাস চুলা,
 ইলেকট্রিক চুলা,
 
</p>
     
             </Alert>


      </Alert>
      <Alert variant="outlined" severity="info">
        
        প্রতিটা পণ্যর সার্ভিস ওয়ারেন্টি আছে।

      </Alert>
      <Alert variant="outlined" severity="warning">
        
<h5>

 প্রতিটি পণ্যের লভ্যাংশ আপনার ব্যালেন্সে জমা হবে 
 
 পণ্য ডেলিভারি হওয়ার পর ।

</h5>

      </Alert>
      <Alert variant="outlined" severity="error">
       
      <sup>
      বিঃদ্রঃ -
      </sup> অযথা পণ্য সার্ভিস request  পাঠাবেন না যদি পাঠান  আপনার একাউন্ট ব্যান হয়ে যেতে পারে ।


      </Alert>
    </Stack>







        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
         


<h4> new request {`(${pendinge})`}</h4>

{pendindata.map((item,inde)=>(

<>

{/* "id": 1,
        "brand_name": "Hzb",
        "product_name": "crt",
        "type_discript": "Bzbz",
        "uname": "Hsbs",
        "phone": "01727",
        "address": "Hgs",
        "user_id": "54",
        "time_create": "2024-01-25T18:00:00.000Z",
        "action": null,
        "oner_name": null,
        "accept_date": null,
        "code": "65432245",
        "profit": null,
        "charg": null,
        
        "offer": null */}
<Alert severity="success" className='m-2 bg-dark text-success'>


  <Button className='pull-right ' style={{position:'absolute', right:80,}}>

  <Tooltip title={
  
  <>

<Delete  onClick={()=>deleted_service(item.id)} titleAccess='deleted ' className='btn m-2 p-1 btn-white' />


<FormatClear onClick={()=>formate_service(item.id)}  titleAccess='formate data' className='btn m-2 p-1 btn-white' />

<Edit onClick={()=>edit_service(item.id)} titleAccess='edit data' className='btn m-2 p-1 btn-white' />


  </>
  
  
 
  
  }  htmlType={<button>deleted</button>}  color='pink' key={item.id}>

  <MoreVertIcon className='pull-right' />

        </Tooltip>


  </Button>
        <AlertTitle>{item.brand_name}</AlertTitle>
        <AlertTitle>{item.product_name}</AlertTitle>
        {item.type_discrip}
      </Alert>

</>

))}


        {/* <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
        This is a success Alert with an encouraging title.
      </Alert>
      <Alert severity="info">
        <AlertTitle>Info</AlertTitle>
        This is an info Alert with an informative title.
      </Alert>
      <Alert severity="warning">
        <AlertTitle>Warning</AlertTitle>
        This is a warning Alert with a cautious title.
      </Alert>
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        This is an error Alert with a scary title.
      </Alert>
    </Stack> */}




        </TabPanel>


        <TabPanel value={value} index={2} dir={theme.direction}>
        <h4> product service waiting {`(${count_pending_confimr })`}</h4>
        </TabPanel>


        <TabPanel value={value} index={3} dir={theme.direction}>
        <h4> Service Complete {`(${count_pending_confimr })`}</h4>
          

three item get

        </TabPanel>
     



        <TabPanel value={value} index={4} dir={theme.direction}>
          
        <h4> Product Delivery {`(${count_pending_confimr })`}</h4>
          four item get
          
                  </TabPanel>
    </Box>






























     <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
         <Box
         className={'bg-dark '}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width:400,
            transform: 'translate(-50%, -50%)',
           color:'#fff',
           
            border: '2px solid #000',
            boxShadow: 24,
            p: 2,
          }}
        >

 <Box sx={{ maxWidth: 400 }}>


 <SettingOutlined spin size={40}  className='text-info ' />

  <h1>

  <Typography.Link href="#" className='h3'>Product Service Request</Typography.Link>
  </h1>


        <Form
    name="complex-form"
    onFinish={onFinish}
    
    style={{
      maxWidth: 600,
    }}
  >
    <Form.Item style={{color:'blue'}} label="Brand name ">
      <Space>
        <Form.Item
          name="brand name "
          noStyle
          rules={[
            {
              required: true,
              message: 'brand name is required',
            },
          ]}
        >
          <input
name='name'
className='form-control'
          onChange={(e)=>set_pro_name(e.target.value)}
            style={{
           
            }}
            placeholder="sansung, walton, nova, symphoney"
          />
        </Form.Item>
        <Tooltip title="Useful information">
          <Typography.Link href="#API">Need Help?</Typography.Link>
        </Tooltip>
      </Space>
    </Form.Item>

   


    <Form.Item label="select product">
      <Space.Compact>
        <Form.Item
          name={['select product', 'province']}
          noStyle
          rules={[
            {
              required: true,
              message: 'select product name is required',
            },
          ]}
        >



          <select className='select form-select'    onChange={(e)=>set_Type(e.target.value)}>

            <option value={'mobile'}>mobile</option>
            <option value={'freez'}>freez</option>
            <option value={'pc'}>pc</option>
            <option value={'crt'}>crt tv</option>
            <option value={'LED'}>LED tv</option>
            <option value={'gash-stove'}>Gash stove</option>
            <option value={'rice-coker'}>Rice coker</option>
            <option value={'more'}>more </option>
         
          </select>



        </Form.Item>












        {/* <Form.Item
          name={['address', 'street']}
          noStyle
          rules={[
            {
              required: true,
              message: 'Street is required',
            },
          ]}
        >
          <Input
            style={{
              width: '50%',
            }}
            placeholder="Input street"
          />
        </Form.Item> */}

      </Space.Compact>
      <br/>
   
    <br/>
 
    
      <TextareaAutosize    onChange={(e)=>setProblem(e.target.value)}  className=' form-control' placeholder='type product problem 1. 2. 3. ' />



    
      


      <br/>








<Form.Item label="Full name"> 
      <Form.Item
          name=" name "
          noStyle
          rules={[
            {
              required: true,
              message: ' name is required',
            },
          ]}
        >
          <input
className='form-control'
onChange={(e)=>set_Name(e.target.value)}

            placeholder="name"
          />
        </Form.Item>
</Form.Item>


<Form.Item label="Phone number">
        <Form.Item
          name="phone number "
          noStyle
          rules={[
            {
              required: true,
              message: 'number 01784.. is required',
            },
          ]}
        >
          <Input
          onChange={(e)=>setPhone(e.target.value)}
           style={{color:'black',Width:199, }}
           className='form-control '
          
            placeholder="phone number 01784.."
          />
        </Form.Item>
</Form.Item>

        
        <Form.Item className='text-white' color={'#fff'} label="Full Address">
        <Form.Item
          name="Full Address "
          noStyle
          rules={[
            {
              required: true,
              message: 'Full Address is required',
            },
          ]}
        >
          <input


onChange={(e)=>setAddress(e.target.value)}
            
              style={{color:'black',Width:199, }}
       

            className='form-control '
            placeholder="Full Address"
          />
        </Form.Item>
        </Form.Item>
        




        </Form.Item>
 



    <Form.Item label="" colon={false}>
      <Button   className='bg-info text-white'  onClick={Requestadd}  htmlType="submit">
        Submit {loding}
      </Button>
    </Form.Item>
  </Form>



  </Box>

        </Box>
      </Modal>





      <FloatButton
      shape="square"
      type="primary"
      onClick={()=>setOpen(true)}
      style={{
        right: 24,
        marginBottom:30,
      }}
      icon={<PlusCircleOutlined /> }
    />

{/* 
      <Box sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1 }}>
      <SpeedDial


onClick={()=>setOpen(true)}
        ariaLabel="SpeedDial openIcon example"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon openIcon={<EditIcon />} />}
      >
        {/* {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
          />
        ))} */}
      {/* </SpeedDial>
    </Box> */}
   


    </div>
  );
}
