import axios from 'axios'
import React,{useState,useEffect} from 'react';
import Ulload from '../logo/my-app.png';

import './Register.css';
import { Link, useNavigate } from 'react-router-dom'
import Urlpost from '../../Url/Urls'
import { Spin } from 'antd';




function Regis() {

  const usavigatess=useNavigate();




  
  const [randUniqid, setRandomNumber] = useState(null);

  // const generateRandomNumber = () => {
  //   const newRandomNumber = Math.floor(Math.random() * 100) + 1; // Generates a random number between 1 and 100
  //   setRandomNumber(newRandomNumber);
  // };

const [name, setName] = useState('')
const [pass, setPass] = useState('')
const [address, setAddress] = useState('')
const [emails, setEmailsadd] = useState('')
const [phone, setPPhone] = useState('')

const [errors, setExit_data]=useState('');


// 'name',	'phone',	'address',	'email',	'pass',	'img',	'uniqid',	


const datas={
  name:name,
  pass:pass,
  address:address,
  email:emails,
  phone:phone,
   img:'logo_user.png',
   unicid:randUniqid,


 }


useEffect(() => {
  getall();
}, [])

const getall=()=>{


  
  // axios.get('https://court.my-shopings.com/public/api/user')
  // .then(res=>{
  //   console.log(res.data.data);
  // });




  Urlpost.get('users')
  .then(response => {
    console.log(response.data.data);
    // Check for specific data conditions and handle accordingly
    if (response.data.error === 'some_error') {
      // Handle 'some_error'
    }
  })
  .catch(error => {
    console.log('Error:', error.message);
  });





}

const registerpage=()=>{
  
  const newRandomNumber = Math.floor(Math.random() * 12000000) + 1; // Generates a random number between 1 and 100
  setRandomNumber(newRandomNumber);
  // axios.post('https://court.my-shopings.com/public/api/postusert',datas)
  // .then(response =>(
  //   alert(response)
  // ));
 


  setExit_data(
    <Spin tip="Loading...">
    <span>Loading...</span>
     </Spin>
);




  Urlpost.post('add_user',datas)
  .then(response => {
    console.log(response.data);

if(response.data.message==true){

console.log('successfully  ');
setExit_data('');

usavigatess('/Login');


}else{                                                                                         

  setExit_data('allready exit phone or email');

}

    // Check for specific data conditions and handle accordingly
    if (response.data.error === 'some_error') {
      // Handle 'some_error'
      console.log('====================================');
      console.log('some errror get alll');
      console.log('====================================');
    }
  })
  .catch(error => {
    console.log('Error:', error.message);



    setExit_data(
      'input text now and try agen'
  );

  });




}





  return (
    



<>

<div className='container'>

  <div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-body">
   
   







      <div class="wrapper">
        <div class="logo">
            <img src={Ulload} alt="" />
        </div>
        <div class="text-center mt-4 name"  >
            my-shopings.com
        </div>
        <div class="p-3 mt-3">

            <div class="form-field d-flex align-items-center" style={{color:'aqua'}}>
                <span  class="far fa-user"></span>
                <input type="text" style={{color:'aqua'}} name="userName" onChange={e =>setName(e.target.value)}    placeholder="userName" />
            </div>

            <div class="form-field d-flex align-items-center">
                <span style={{color:'aqua'}} class="fa fa-envelope-square"></span>
                <input type="email"  style={{color:'aqua'}} onChange={e =>setEmailsadd(e.target.value)} name="email address"  placeholder="Email address" />
            </div>

            <div class="form-field d-flex align-items-center">
                <span style={{color:'aqua'}} class="fa fa-phone-square"></span>
                <input type="text" maxLength={11} max={11}   style={{color:'aqua'}}   onChange={e =>setPPhone(e.target.value)} name="number"  placeholder="Phone number" />
            </div>


            <div class="form-field d-flex align-items-center">
                <span style={{color:'aqua'}} class="fa fa-address-card"></span>
                <input type="text"   style={{color:'aqua'}}   maxLength={20} onChange={e =>setAddress(e.target.value)} name="address"  placeholder="Address" />
            </div>


            <div class="form-field d-flex align-items-center">
                <span style={{color:'aqua'}} class="fas fa-key"></span>
                <input type="password"   style={{color:'aqua'}}  maxLength={20} onChange={e =>setPass(e.target.value)} name="password"  placeholder="Password" />
            </div>


            <div className='text-danger'>
              {errors}
            </div>
           
        </div>
        <button onClick={registerpage} class="btn mt-3">Sign Up</button>
        <div class="text-center fs-6">
        <br/>
            <Link to={'/Login'}> <a href="#sign-up" className=''  >Login</a>  </Link>
        </div>
    </div>









    </div>
  </div>
</div>

</div>















</>











  )
}

export default Regis