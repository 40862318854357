import axios from "axios";


const axiosInstance = axios.create({
  baseURL: "https://api.my-shopings.com/", // Replace with your API base URL
  timeout: 8000, // Set the timeout for requests (in milliseconds)
  headers: {
    'Content-Type': 'application/json',
    'Content-Type': 'multipart/form-data',
    // Add any other common headers you need
  },
});

export default axiosInstance