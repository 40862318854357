import React, { useState, useEffect } from 'react';

import { Column } from '@ant-design/plots';




function Balanc_carts() {

  const [data, setData] = useState([]);

  useEffect(() => {
    asyncFetch();
  }, []);

  const asyncFetch = () => {
    fetch('https://gw.alipayobjects.com/os/bmw-prod/be63e0a2-d2be-4c45-97fd-c00f752a66d4.json')
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  };
  const config = {
       data,
    xField: '城市',
    yField: '销售额',
    xAxis: {
      label: {
        autoRotate: false,
      
      },
    },
    slider: {
      start: 0.1,
      end: 0.2,
    },
    
  };


  return (
  
<div className='text-white '>
<Column class={'text-white bg-dark'}  height={200} {...config} />
</div>



   
   
  )
}

export default Balanc_carts