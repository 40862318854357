import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Top_menu from '../Menu/Top_menu'
import Top_mLInk from '../Menu/Top_LInk'
import Product_home_page from '../product_page/Product_home_page'
import Prodcut_2p_Page from '../product_page/Prodcut_2p_Page'
import Regis from '../Regis/Regis'



import Homeprod from "../../Home_home"


import Home_search from '../Redux/SearchBar'


import Product_cagro from '../../Product_catagory'

import Login from '../Regis/Login'
import Products_catagoris from '../index/Products_catagoris'
import Product_cards from '../index/Product_cards'
import User_profile from '../Regis/User_profile'
import Forget_password from '../Regis/Profle/Forget-password'
import Change_password from '../Regis/Profle/Change_password'
import Winner_spin from '../Winner/Winner_spin'

import View_product from '../product_page/Viewproduct/Product_view'
import { Helmet } from 'react-helmet-async'
import Age_calclutor from '../Regis/Profle/Age_calclutor'
import Index from '../Regis/Profle/Blood_or_video_content/Index'





export default function Routers() {









  
  return (
   
<>
{/* <Top_mLInk /> */}
<div className=''>
    <div className=''>

<Routes>




<Route path='/Register' element={<Regis  />} />













<Route path='/Login' element={<Login />} />

{/* <Route path='/products/:catagory/:model' element={<Product_cagro />} />
<Route path='/products/:catagory' element={<Product_cagro />} /> */}





<Route path='/catagory/:catagoy/:old' element={<Products_catagoris  />} />




<Route path='/catagory/:catagoy/:old/:brand' element={<Products_catagoris  />} />



<Route path='/ordr_list_tusers' element={<Product_cards />} />



<Route path='/user-profles' element={<User_profile /> } />



<Route path='/' element={<Homeprod />} />


<Route path='/password_change' element={   <Change_password  />   }  />


<Route path='/Forget-password' element={  <Forget_password />   }  />




<Route path='/Winnner_spiners' element={   <Winner_spin  />   }  />






<Route path='/home_search' element={ <Home_search /> }  />





<Route path='/Product-view' element={<View_product />} />
<Route path='/Product-view/:uni' element={<View_product />} />
<Route path='/Product-view/:uni/:id' element={<View_product />} />





<Route path='/Age' element={<Age_calclutor />} />







<Route path='*' element={<Prodcut_2p_Page />} />


















{/* this is News Feed */}





<Route path='/newsfeed' element={<Index />} />




</Routes>







</div>
</div>


</>





  )
}
