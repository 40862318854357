import React,{useEffect,useState} from 'react';

import Rouers from  '././Commponet/Redux/Routers'
import { useNavigate,BrowserRouter } from 'react-router-dom';



import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import axiosInstance from './Url/Apinode';



import laravelapis from './Url/Urls';
import { Helmet } from 'react-helmet-async';


export default function App() {



  useEffect(() => {

    GetItemdata();

    const ids={
      id:localStorage.getItem('id')
    }
    laravelapis.post('incrementPageViews',ids)


.then(res => {




  console.log('view add all id and not id====================================');
 

  console.log(res.data);

  console.log('====================================');
})
.catch(error =>{
 console.log('viewEEEEEEEEEEEEEEERRRRRRRRRRRRRRRROOOOO  view  ====================================');
 console.log();
 console.log(error,'error catch');
})

 
  }, [])
  
 
const handerlsactons=()=>{



console.log('error upadte values this a vjiew update ');
console.log('error upadte values this a vjiew update ');
console.log('error upadte values this a vjiew update ');
console.log('error upadte values this a vjiew update ');
console.log('error upadte values this a vjiew update ');
console.log('error upadte values this a vjiew update ');
console.log('====================================');



}

const [seoItem, setOAlldata] = useState([])
const GetItemdata=()=>{
  axiosInstance.get('/product_get')


  .then(res => {
  
  
  
  
    console.log('view Seo  seo ====================================');
   
    setOAlldata(res.data);
    console.log(res.data);
  
    console.log('seo ====================================');
  })
  .catch(error =>{
   console.log('v============');
   console.log();
   console.log(error,'error catch');
  })


}



  return (
    <>

<div onKeyUp={handerlsactons}>




  
<Helmet prioritizeSeoTags>
  <title>my-shopings.com</title>
  {seoItem.map((item,inde)=>  (
  <>


  <link rel="my-shopings.com" href="https://www.my-shopings.com" />
  <meta name="my-shopings.com" value="my-shopings.com" />
 <link rel="my-shopings.com" href="https://www.my-shopings.com" />
 <link rel="my-shopings"href="https://www.my-shopings.com" />
 <link rel="my-shop" href="https://www.my-shopings.com" />
  <meta property="my-shopings.com" content="https://www.my-shopings.com"/>

  <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />

  <link rel="my-shopings.com" href={`https://www.my-shopings.com/catagory/${item.catagri}/${item.old}/${item.brand}`} />

  <link rel="my-shopings.com"    href={item.imglink} />

  <link rel="my-shopings.com"    href={`https://court.my-shopings.com/public/uploads_product/${item.img}`} />

  <meta property="og:image" itemProp='image' content={`https://court.my-shopings.com/public/uploads_product/${item.img}`} /> 
  <meta property="og:image" itemProp='image' content={item.imglink} /> 
  
  <meta property="my-shopings.com:brand" content={`https://www.my-shopings.com/catagory/${item.catagri}/${item.old}/${item.brand}`} />

  <meta property="my-shopings.com:brand" content={item.brand} /> 
  <meta property="my-shopings.com:catagory" content={item.catagori} /> 
  <meta property="my-shopings.com:discription" content={item.discript} /> 
   
  <meta property="my-shopings.com:model" content={item.model} /> 








  <meta property="my-shopings.com:Product-View" content={`https://www.my-shopings.com/Product-View/${item.uniqid}`} />

  <meta property="twitter:Product-View" content={`https://www.my-shopings.com/Product-View/${item.uniqid}`} />

  <meta property="my-shopings.com:Name" content={item.name} /> 
  <meta property="my-shopings.com:tag" content={item.tag} /> 
  <meta property="my-shopings.com:price" content={item.pricee} /> 
</>

    

  ))}


  <link rel="my-shopings.com" href="https://www.my-shopings.com" />
  <meta name="my-shopings.com" value="my-shopings.com" />
  <link rel="my-shopings.com" href="my-shopings.com" />
  <meta property="my-shopings.com" content="my-shopings.com"/>








{/* 
   brand
: 
"ছেলেদের"
catagori
: 
"ছেলেদের"
color
: 
null
color1
: 
null
color2
: 
null
createtime
: 
"2024-01-02T18:00:00.000Z"
creatid
: 
"22"
discript
: 
"panth, shart, genji, jota"
hide
: 
"show"
id
: 
121
img
: 
"1704257722_rashdiul islam-my-shopings.com_4.png"
imglink
: 
null
model
: 
"01"
name
: 
"all shop"
offerprice
: 
"01"
old
: 
"new"
pag
: 
"on"
pricee
: 
"01"
product_profit
: 
""
profit
: 
"01"
reprice
: 
"01"
size1
: 
null
size2
: 
null
size3
: 
null
size4
: 
null
size5
: 
null
size6
: 
null
stock
: 
null
storename
: 
"me"
tag
: 
"tag, new"
uniqid
: 
"618806"
update
: 
"2024-01-02T18:00:00.000Z"
updateid
: 
"22"
video
: 
null */}

{/* <meta name='description' content={} />
 { /* End standard metadata tags */ }
{ /* Facebook tags */ }
 {/* <meta property="og:type" content={} />
<meta property="og:title" content={} />
 <meta property="og:description" content={} /> */}
 { /* End Facebook tags */ }
 { /* Twitter tags */ }
{/* <meta name="twitter:creator" content={} />
 <meta name="twitter:card" content={} />
<meta name="twitter:title" content={} />
 <meta name="twitter:description" content={} /> */} 


</Helmet>







  

<BrowserRouter>

<ToastContainer />



<Rouers />









</BrowserRouter>

</div>

    </>
  );
}
















// import React from 'react';
// import { Helmet } from 'react-helmet-async';
// export default function SEO({title, description, name, type}) {

// <Helmet>
// { /* Standard metadata tags */ }
// <title>{title}</title>
// <meta name='description' content={description} />
// { /* End standard metadata tags */ }
// { /* Facebook tags */ }
// <meta property="og:type" content={type} />
// <meta property="og:title" content={title} />
// <meta property="og:description" content={description} />
// { /* End Facebook tags */ }
// { /* Twitter tags */ }
// <meta name="twitter:creator" content={name} />}
// <meta name="twitter:card" content={type} />
// <meta name="twitter:title" content={title} />
// <meta name="twitter:description" content={description} />
// { /* End Twitter tags */ }
// </Helmet>
