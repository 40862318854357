

// import React from 'react'

// function Wallet() {
//   return (
//     <div>Walle sdsdt</div>
//   )
// }

// export default Wallet

import React,{useState,useEffect} from 'react'

import Settings from '@mui/icons-material/Settings'
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Card } from '@mui/material'


import Button from '@mui/joy/Button';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';


import AddShoppingCartSharpIcon from '@mui/icons-material/AddShoppingCartSharp';
import { SetOutline, UserAddOutline } from 'antd-mobile-icons'
import { CheckCircleTwoTone, HeartTwoTone, BellOutlined } from '@ant-design/icons';

import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';


import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import PaymentIcon from '@mui/icons-material/Payment';

import Balanc_carts from './Carts/Balanc_carts'

import { Avatar, Col, List, Row, Space, Tabs,Alert, Modal,Typography, Image } from 'antd'



import ReplyAllSharpIcon from '@mui/icons-material/ReplyAllSharp';

import SettingsAccessibilitySharpIcon from '@mui/icons-material/SettingsAccessibilitySharp';


import ChangeCircleSharpIcon from '@mui/icons-material/ChangeCircleSharp';


import CurrencyExchangeSharpIcon from '@mui/icons-material/CurrencyExchangeSharp';

import TrendingDownIcon from '@mui/icons-material/TrendingDown';

import ShowChartIcon from '@mui/icons-material/ShowChart';

import BarChartIcon from '@mui/icons-material/BarChart';

import QueryStatsIcon from '@mui/icons-material/QueryStats';



import Nodejsapi from '../../../Url/Apinode'




{/* <BellOutlined   ></BellOutlined> */}


const TabPane = Tabs.TabPane;


function Wallet() {






useEffect(() => {

  GEtipendings_Reject();

  GEtipendings();

  GEtipendings_Waitins();

  GEtipendings_Cofirm();


  // Userbalnace();

}, [])








useEffect(() => {


  Userbalnace();


  
}, [])





const [moneysques, setMOneyreauestrue] = useState(true)

const [balances, setUsteBalance] = useState(0)




const Userbalnace=()=>{





  // if( 999 > balances){
  //   setMOneyreauestrue(true);
  // }else{
  //   setMOneyreauestrue(false);
  // }



  let ids=localStorage.getItem('id');



  Nodejsapi.get(`user_balance_id_sum/${ids}`)

  .then(res =>{

    console.log('balanc get 99999999999999');

    console.log(res.data[0].Balance);


  
    if (999 > balances) {
      console.log('balance gets trueeeeeeeeeeeee');
      setMOneyreauestrue(true); // I assumed you meant to set this to true when the balance is 555.
    } else {
      console.log('balance gets 8888 falsesssssssssssss 99999999999999');
      setMOneyreauestrue(false);
    }

    // if(res.data[0].Balance === 555){
    //   console.log('balanc get 555555555trueeeeeeeeeeeee');
    //   setMOneyreauestrue(true);
    // }else{
    //   console.log('balanc get   8888 falsesssssssssssss 99999999999999');
    //   setMOneyreauestrue(false);
    // }
 
    console.log('====================================');
    setUsteBalance(res.data[0].Balance);
  })
  .catch(error =>{

    console.log('error balabcer');

    console.log('login banalcerssssssssssssssssssss66666666666=======6666600000000000'); 

    setUsteBalance(0);
  })


}















  let username=localStorage.getItem('username');


let Urlimg=localStorage.getItem('img');






  const [open, setOpen] = useState(false);
  const [ordertext, setOrdernmae] = useState('');






  const showModal = (text) => {
    setOpen(true);


    setOrdernmae(text);

let pandin=text;

  


  };




  const [open1, setOpen1] = useState(false);
  const showModal1 = (text) => {
    setOpen1(true);


    setOrdernmae(text);




  };




  const [open2, setOpen2] = useState(false);
  const showModal2 = (text) => {
    setOpen2(true);


    setOrdernmae(text);




  };



  
  const [open3, setOpen3] = useState(false);
  const showModal3 = (text) => {
    setOpen3(true);


    setOrdernmae(text);




  };



  const [orderall, setOrderpandings] = useState([])
  const [lengths_orders, setOrderpandings_length] = useState(0)

const GEtipendings=()=>{


  let ids=localStorage.getItem('id');
    
  // Urlaxiso
  // .then(res=>{


Nodejsapi.get(`get_users_orders_get_pending/${ids}`)

.then(res =>{


  setOrderpandings_length(res.data.length);
setOrderpandings(res.data);
console.log('====================================  10000');
console.log(res.data);
console.log('====================================');
})

.catch(error =>{




setOrderpandings([]);
console.log('5555555555  =    420   ===================================');
console.log(error, 'error ');
console.log('====================================');
})



}





// get_users_orders_get_Waiting




// get_users_orders_get_reject



const [order_reject, setOrderWaitinRejects] = useState([])

const [lengths_oreject, setOrderpandings_rejects] = useState(0)

const GEtipendings_Reject=()=>{


  let ids=localStorage.getItem('id');
    
  // Urlaxiso
  // .then(res=>{


Nodejsapi.get(`get_users_orders_get_reject/${ids}`)

.then(res =>{

  setOrderpandings_rejects(res.data.length);
  setOrderWaitinRejects(res.data);
console.log('====================================  Waitings');
console.log(res.data);
console.log('====================================');
})

.catch(error =>{




  setOrderWaitinRejects([]);
console.log(' setOrderWaitins   ===================================');
console.log(error, 'error ');
console.log('====================================');
})



}









const [order_confirm, setOrde_confirm] = useState([]);

const [lengthss, setLenght] = useState(0)

const GEtipendings_Cofirm=()=>{


  let ids=localStorage.getItem('id');
    
  // Urlaxiso
  // .then(res=>{


Nodejsapi.get(`get_users_orders_get_confirm_order/${ids}`)

.then(res =>{

  setLenght(res.data.length);

  setOrde_confirm(res.data);
console.log('====================================  Waitings');
console.log(res.data);
console.log('====================================');
})

.catch(error =>{




  setOrderWaitinRejects([]);
console.log(' setOrderWaitins   ===================================');
console.log(error, 'error ');
console.log('====================================');
})



}





const [ordeWaiting, setOrderWaitins] = useState([])


const [pendigs, setOrderpPendings_length] = useState(0)

const GEtipendings_Waitins=()=>{


  let ids=localStorage.getItem('id');
    
  // Urlaxiso
  // .then(res=>{


Nodejsapi.get(`get_users_orders_get_Waiting/${ids}`)

.then(res =>{


  setOrderpPendings_length(res.data.length);


  setOrderWaitins(res.data);
console.log('====================================  Waitings');
console.log(res.data);
console.log('====================================');
})

.catch(error =>{




  setOrderWaitins([]);
console.log(' setOrderWaitins   ===================================');
console.log(error, 'error ');
console.log('====================================');
})



}








const hideModal3 = () => {
  setOpen3(false);
};


const hideModal2 = () => {
  setOpen2(false);
};



const hideModal1 = () => {
  setOpen1(false);
};


  const hideModal = () => {
    setOpen(false);
  };


















  return (
   <>
<div  style={{background:'#1e2535',color:'aqua'}} className='p-3 container' >








<div className='container card'  style={{background:'#1e2535',  boxShadow:' inset white 1px 1px 6px'}} >




        
        {/* <List
        className='text-white'
      itemLayout="horizontal">
    
     
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar size={60}  icon={<UserAddOutline />}    />}
            title={<> <h5 className='t'>Md rasdid</h5> </>}
            
          />
  
  
        </List.Item>

        
     
  </List>
   */}
  
  
  
 

   
         <List
          style={{background:'#1e2535'}} 
        className='text-white '
      itemLayout="horizontal">
    
     
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar size={60}  style={{color:'aqua'}}   src={`https://court.my-shopings.com/public/profile_users/${Urlimg}`} icon={<UserAddOutline />}    />}
            title={<> <h5 className='text-white' style={{color:'aqua'}} >{username} </h5> </>}
     
            
          />
  







  <Settings style={{color:'aqua'}} className='card bg-dark shadow'  spine  />



        </List.Item>

        
     
  </List>





  



{/* this is a balance chaert */}


<Card  className='p-2 m-2' style={{background:'#1e2535', boxShadow:' inset white 1px 1px 6px'}} bordered={false}>




<List
          style={{background:'#1e2535'}} 
        className='text-white '
      itemLayout="horizontal">
    
     
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar size={60}  style={{color:'aqua'}} 
            
               icon={'৳'}    />}
            title={<> <h6 className='' style={{color:'aqua'}} >Balance{''} </h6> </>}
            description={<p style={{color:'aqua', fontSize:''}}> <span className='text'>{balances ?
              <> {balances}</>
            :
            <>{'00.0'}</>
            
            }  </span> Tk</p>}
     
            
          />
  







  {/* <Settings style={{color:'aqua'}} className='card bg-dark shadow'  spine  /> */}



        </List.Item>

        
     
  </List>







<Balanc_carts  />


</Card>




<div className='conatiner m-4' >
<Row gutter={16} >


  




     


































</Row>


    
</div>



  
  
       
  
{/* 



<Row gutter={16}>


  

     
     
      

  




<Col span={8}>



<Card title="Card title" bordered={false}>
  Card content
</Card>





<Card title="Card title" bordered={false}>
  Card content
</Card>


</Col>




    <Col span={8}>



      <Card title="Card title" bordered={false}>
        Card content
      </Card>





      <Card title="Card title" bordered={false}>
        Card content
      </Card>


    </Col>



  </Row> */}


















<div className="card-container text-center">
    <Tabs type="">

    <TabPane tab={     <AddShoppingCartSharpIcon style={{color:'aqua', fontSize:35,}}   className=' btn  bg-dark shadow p-2 m-1 '    />} key="3">
     
     
<>


<div className='p-2 m-2'>


<div className='row text-center'>




<div className='  card bg-dark col-4  btn m-2 p-2' style={{color:'aqua'}} onClick={()=>showModal('Order')}>


<span className='p'> new order</span>





<span className='m-1'> <BarChartIcon /> {lengths_orders}</span>



</div>







<div className=' card bg-dark  col-4 btn m-2 p-2' style={{color:'aqua'}} onClick={()=>showModal1('pending')}>

{/* this a waiting */}

<span>  pending</span>

<span className='m-1'>  <QueryStatsIcon />  {pendigs}  </span>


</div>



<div className=' card bg-dark  col-4 btn m-2 p-2' style={{color:'aqua'}} onClick={()=>showModal2('reject')}>

<span>  reject </span>

<span className='m-1'> <TrendingDownIcon />  {lengths_oreject}</span>


</div>






<div className=' card bg-dark btn col-4  m-2 p-2' style={{color:'aqua'}} onClick={()=>showModal3('comfirm')}>

<span> order accept </span>

<span className='m-1'>   <ShowChartIcon /> {lengthss}</span>



</div>






</div>










</div>

{/* <label for="browser" class="form-label">Choose your browser from the list:</label>
<input class="form-control" list="browsers" name="browser" id="browser" />
<datalist id="browsers">
  <option value="Edge" />
  <option value="Firefox" />
  <option value="Chrome" />
  <option value="Opera" />
  <option value="Safari" />
</datalist> */}


</>

      </TabPane>













    <TabPane  disabled={moneysques}   tab={<span> <Button disabled={moneysques}  className='bg-dark btn p-2 m-1'   style={{background:'#1e2535', color:'aqua', boxShadow:' inset white 1px 1px 6px'}}>  <AccountBalanceWalletIcon />  টাকা উত্তোলন </Button> </span>}  key="4">
      

<>





{/* 
<form className='p-4 m-4'
      onSubmit={(event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        alert(JSON.stringify(formJson));
      }}
    >
      <Stack spacing={1}>
        <Input placeholder="Try to submit with no text!" required />
        <Input placeholder="It is disabled" disabled />
        <Button type="submit">Submit</Button>
      </Stack>
    </form> */}


    <div className='p-2 m-2'>


<div className='row text-center'>




<div className=' btn card bg-dark col-4 m-2 p-2' style={{color:'aqua'}} >

<span>Cashout</span>

<span> <CurrencyExchangeSharpIcon spine /> 45,000</span>



</div>







<div className=' btn card bg-dark col-4 m-2 p-2' style={{color:'aqua'}} >

<span>money Trasfrom </span>
<span><ChangeCircleSharpIcon /> </span>



</div>



<div className=' btn card bg-dark col-4 m-2 p-2' style={{color:'aqua'}} >

<span className=''> information </span>

<span className='m-1'>


<SettingsAccessibilitySharpIcon />

</span>


</div>






<div className=' btn card bg-dark col-4  m-2 p-2' style={{color:'aqua'}} >

<span>  accept </span>

<span className='m-1'>

<ReplyAllSharpIcon />
45,00</span>



</div>






</div>










</div>










</>








      </TabPane>

    





      <TabPane     tab={<NotificationsIcon style={{color:'aqua', fontSize:35,}}  className=' btn  bg-dark shadow p-2 m-1 '    />} key="1">
       

<>




<div className='m-2 p-2'>



<div className='row'>



<Space
    direction="vertical"
    style={{
      width: '100%',
    }}
  >




    <Alert
      message="আইডি সাথে মিল রেখে নাম ও বয়স রেজিস্ট্রেশন করবেন ঃঃ  অন্যথায় একাউন্টি অচল হইয়া যেতে পারে !"
      
      type="success"
      showIcon
     
      closable
    />






    <Alert
      message ="টাকা উত্তলোন করার সময় "
      showIcon
      description="টাকা উত্তোলন করার সময় অবশ্যই আপনার একাউন্টের নাম ও ঠিকানা  ন্যাশনাল এন আইডির সাথে মিল  রেখে ফিলাপ করবে ।  অন্যথায় আপনার টাকা উত্তলন অসম্পর্ণ হবে।
      "
      type="error"
      
    />




    <Alert
      message="আপনার একাউন্টের টাকা উত্তোলন করতে পারবেন সর্ব নিম্ন ১,০০০/-  (এক হাজার ) টাকা হলে। "
      
      closable
    />


{/* 

    <Alert
      message="Info Text"
      description="Info Description Info Description Info Description Info Description"
      type="info"
      action={
        <Space direction="vertical">
          <Button size="small" type="primary">
            Accept
          </Button>
          <Button size="small" danger ghost>
            Decline
          </Button>
        </Space>
      }
      closable
    />
 */}





  </Space>

 



</div>

</div>



</>


      </TabPane>











      <TabPane disabled={true} tab={  <PaymentIcon style={{color:'aqua', fontSize:35,}} disabled={true} className=' btn  bg-dark shadow p-2 m-1 '    />} key="2">
        <p>edit option development</p>
       
      </TabPane>






  

  

  






    </Tabs>
  </div>





      </div>






</div>










{/* this model all order get data  */}
{/* <Button type="primary" onClick={showModal}>
        Modal
      </Button> */}




{/* this order pendings order */}

      <Modal
        title={ordertext}
        open={open}
className='bg-dark'
        onOk={hideModal}
        onCancel={hideModal}
        footer={ordertext}
      >
       


<div className='card'>


{orderall ? 

<>

{orderall.map((item)=>(




  <Alert
  message={
    <>

{item.img ?


<Image


style={{height:'100px', width:'100px'}}

srcSet={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
src={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
alt={item.tag}
loading="lazy"
/>

:

<Image



style={{height:'100px', width:'100px'}}

srcSet={item.imglink}
src={item.imglink}
alt={item.tag}
loading="lazy"


/>

}
    
    {item.name}
    </>
    }
      type="warning"
      action={
        <Space>
          <mark>{item.price}</mark>
        </Space>
      }
      closable
    />





))}


</>
:
<>

  <h4>no data fetch </h4>
</>
}



</div>










      </Modal>

















      
{/* this order Waitings order */}

<Modal
        title={ordertext}
        open={open1}
className='bg-dark'
        onOk={hideModal1}
        onCancel={hideModal1}
        footer={ordertext}
        
      >
       


<div className='card'>


{ordeWaiting ? 

<>

{ordeWaiting.map((item)=>(




  <Alert
  message={
    <>

{item.img ?


<Image


style={{height:'100px', width:'100px'}}

srcSet={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
src={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
alt={item.tag}
loading="lazy"
/>

:

<Image



style={{height:'100px', width:'100px'}}

srcSet={item.imglink}
src={item.imglink}
alt={item.tag}
loading="lazy"


/>

}
    
    {item.name}
    </>
    }
      type="warning"
      action={
        <Space>
          <mark>{item.price}</mark>
        </Space>
      }
      closable
    />





))}


</>
:
<>

  <h4>no data fetch </h4>
</>
}



</div>










      </Modal>











{/* this order Confirm order */}

<Modal
        title={ordertext}
        open={open2}
className='bg-dark'
        onOk={hideModal2}
        onCancel={hideModal2}
        footer={ordertext}
        
      >
       


<div className='card'>


{order_reject ? 

<>

{order_reject.map((item)=>(



  <Alert
  message={
    <>

{item.img ?


<Image


style={{height:'100px', width:'100px'}}

srcSet={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
src={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
alt={item.tag}
loading="lazy"
/>

:

<Image



style={{height:'100px', width:'100px'}}

srcSet={item.imglink}
src={item.imglink}
alt={item.tag}
loading="lazy"


/>

}
    
    {item.name}
    </>
    }
      type="warning"
      action={
        <Space>
           <mark>{item.price}</mark>
        </Space>
      }
      closable
    />






))}


</>
:
<>

  <h4>no data fetch </h4>
</>
}



</div>










      </Modal>






      
{/* this order Reject order */}

<Modal
        title={ordertext}
        open={open3}
className='bg-dark'
        onOk={hideModal3}
        onCancel={hideModal3}
        footer={ordertext}
        
      >
       


<div className='card'>


{order_confirm ? 

<>

{order_confirm.map((item)=>(




  <Alert
      message={
        <>

{item.img ?
    

    <Image


style={{height:'100px', width:'100px'}}
 
 srcSet={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
 src={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
 alt={item.tag}
 loading="lazy"
/>

  :

  <Image
 

 
style={{height:'100px', width:'100px'}}

 srcSet={item.imglink}
 src={item.imglink}
 alt={item.tag}
 loading="lazy"


/>

  }
        
        {item.name}
        </>
        }
      type="warning"
      action={
        <Space>
         <mark>{item.price}</mark>
        </Space>
      }
      closable
    />







))}


</>
:
<>

  <h4>no data fetch </h4>
</>
}



</div>










      </Modal>



<br/>
<br/>
<br/>
<br/>
<br/>
<br/>

   </>
  )
}

export default Wallet