import React,{useState, useEffect} from 'react';
import Table from '@mui/joy/Table';

import Sheet from '@mui/joy/Sheet';

import { Image, InputNumber } from 'antd';

import { DeleteOutline } from 'antd-mobile-icons';

import Urlaxiso from './../../../Url/Apinode'

import Urllaravelapi from './../../../Url/Urls'

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }
// const rows = [
//   createData('1', 159, 6.0, 24, 4.0),
//   createData('2', 237, 9.0, 37, 4.3),
//    createData('6', 159, 6.0, 24, 4.0),
//   createData('7', 237, 9.0, 37, 4.3),
//   createData('8', 262, 16.0, 24, 6.0),
//   createData('9', 305, 3.7, 67, 4.3),
//   createData('10', 356, 16.0, 49, 3.9),
// ];

// function sum(column) {
//   return rows.reduce((acc, row) => acc + row[column], 0);
// } createData('3', 262, 16.0, 24, 6.0),
//   createData('4', 305, 3.7, 67, 4.3),
//   createData('5', 356, 16.0, 49, 3.9),


export default function TableStickyHeader() {




    const [allorders, setDAtaall] = useState([])

  

    const [countss, setToquntiy] = useState('')





    // let calculateSubtotal = (allorders, price) => {
    //   return allorders.reduce((total, item) => total + item[price], 0);
    // };
    

// const allorder=allorders.map(item=>(setTotal(total+item.price)));


const [totallprice, setTotalprice] = useState([])


useEffect(() => {
  

  order_get_prices();



}, [allorders])

const order_get_prices=()=>{
    
  let ids=localStorage.getItem('id');

  Urlaxiso.get(`get_users_orders_prices/${ids}`)
  .then(res=>{
 


  setTotalprice(res.data[0]);
 
  
console.log('====================================');
console.log(' order table data get ');
console.log(res.data.length);
  
      
  }).catch(error=>{
      





    setTotalprice('');






      console.log(' order table get error otion====================================');

      console.log('====================================');
      
  })

  
}







    
    useEffect(() => {


    userOrdrsall()

   

        
    }, [totallprice])





    
    const userOrdrsall=()=>{
    
        let ids=localStorage.getItem('id');
    
        Urlaxiso.get(`get_users_orders/${ids}`)
        .then(res=>{
      

        setToquntiy(res.data.length);
        setDAtaall(res.data);

        
    console.log('====================================');
    console.log(' order table data get ');
    console.log(res.data.length);
        
            
        }).catch(error=>{
            

          setToquntiy('1');
          setDAtaall('1');

            console.log('====================================');
            console.log('eror options', error);
            console.log('====================================');
            
        })
    
        
    }
    
    






    const onChange = (value,id) => {
        


      



      Urlaxiso.get(`order_confrom_dasbord_quntity_add/${id}/${value}`)
      .then(res=>{
      console.log(res.data);
    
  
console.log('====================================');
console.log('update ok quntity');
console.log('====================================');

    
          
      }).catch(error=>{
         console.log('error quntity update error');
          
      })
  
  



      };



      const Deltedst=(id)=>{


        Urllaravelapi.delete(`order_delelet/${id}`)
        .then(res=>{
        console.log(res.data);
       
    console.log(res.data.message);
  
    console.log('===========================Deleted product =========');
    
            
        }).catch(error=>{
       console.log('====================================');
       console.log('please wait delted');
       console.log('====================================');
            
        })
    
    
    }





  //   {allorders.map(values => {
  //     console.log(values, "values")
  //     var pharmacyTotal = 0;
  //     var clientTotal = 0;
      
  //     for (let i = 0; i < values.length; i++) {
  //       pharmacyTotal += values[i].price;
  //       clientTotal += values[i].price;
  //     }

  //   })

  // }

    // {Object.values(grouppedArray).map(values => {
    //   console.log(values, "values")
    //   var pharmacyTotal = 0;
    //   var clientTotal = 0;
      
    //   for (let i = 0; i < values.length; i++) {
    //     pharmacyTotal += values[i].pharmacy;
    //     clientTotal += values[i].client;
    //   }


    // }

  return (
    <div className='responsive'>
     






      <Sheet sx={{ height: 400, overflow: 'auto' }}>
        <Table

        
borderAxis="bothBetween"
          aria-label="table with sticky header"
          stickyHeader
          stickyFooter
          stripe="odd"
          hoverRow

          
        
          
          

        >

          <thead>
            <tr>
              <th>count</th>
              <th>img</th>
              <th>name</th>
              <th>type</th>
              <th>price</th>
              <th>qunitity</th>
              <th>sumtotal</th>
              <th>action</th>
              {/* <th>Fat&nbsp;(g)</th> */}
           
            </tr>
          </thead>
          <tbody>



            {allorders ?    allorders.map((row,index) => (
              <>

          



              <tr key={row.id}>
                <td>{index+1}</td>

                <td>
                
                {row.img ?
    

    <Image
 
 srcSet={`https://court.my-shopings.com/public/uploads_product/${row.img}`}
 src={`https://court.my-shopings.com/public/uploads_product/${row.img}`}

 loading="lazy"
/>

  :

  <Image
 
 srcSet={row.imglink}
 src={row.imglink}

 loading="lazy"
/>

  }


           
                
                
                </td>
                <td>{row.name}</td>
                <td>{row.model}</td>
                <td>{row.price}</td>
                <td>
                
                <InputNumber style={{width:'50px'}} min={1} max={100} defaultValue={row.quntity} onKeyUp={(e)=>onChange(e.target.value, row.id)} />
                
                </td>

                <td>{row.price * row.quntity }        </td>



                <td>

                <DeleteOutline fontSize={20}  onClick={()=>Deltedst(row.id)}  className='m-2' /> 



                </td>
              
              </tr>

            
              </>
            ))
            
            :
            <h3>not order data back now order</h3>
            }






          </tbody>
          <tfoot>
            <tr>

              <th scope="row">count</th>

              <td>{countss}</td>
              <td></td>
              <td></td>
              <td> 
               item:
              </td>
              <td>   { countss ?  
             <> {totallprice.product_count} </> 
              :
              
              null}</td>

              

              <td> </td>
          
              <td>     </td>


              <td></td>
         
            </tr>
            <tr>
              <td colSpan={5} style={{ textAlign: 'center' }}>
             

            Total Bill :   {countss ? <>   {totallprice.TOTAL_PRICE} </> : null} /-
  

{/* setTotalprice(res.data[0].product_count);
  setTotalprice(res.data[0].TOTAL_PRICE); */}

              </td>
            </tr>
          </tfoot>
        </Table>
      </Sheet>
    </div>
  );
}