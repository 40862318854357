import React, { useState } from 'react'


import Buttoms from './Profle/Buttom_menu'

function User_profile({data}) {


  return (
    <>







      <Buttoms    />
    </>
  )
}

export default User_profile