import React, { useEffect, useState } from 'react'
import { Card, Toast } from 'antd-mobile'






 import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';






import { toast } from "react-toastify";


// import Dialog_product_dital from './Product_table/Model/Ditails'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import  Buttons from '../Menu/Bottom_navbar'

import { Carousel, Badge, Image} from 'antd'

import { IconButton, ImageList, ImageListItem, ImageListItemBar } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info';
// import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ShoppingCart from '@mui/icons-material/ShoppingCart'


// ShoppingCartOutlined

import LarveApi from '../../Url/Urls'


import NodejsApi from '../../Url/Apinode'



import FilterListIcon from '@mui/icons-material/FilterList';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';

import ListIcon from '@mui/icons-material/List';


import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';

import SearchIcon from '@mui/icons-material/Search';










import Grid from '@mui/material/Unstable_Grid2';
import { Helmet } from 'react-helmet-async';







const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));













// <ShoppingCart



function Products_catagoris() {


const usernavigates=useNavigate()

 const {catagoy, old, brand}= useParams();



  useEffect(() => {


    localStorage.setItem('olds', 'new');
  
    
 
    get_products();

    get_products_barnd();

  
  
  
  
  }, [brand])


  
  const [productss, setDataproducts] = useState([])

  const get_products=()=>{
  
    
  let ids=old;

  // let catagory=localStorage.getItem('catagoris');
  
  NodejsApi.get(`cata/${ids}/${catagoy}`)
  .then(res=>{
  console.log('====================================');
  
  console.log(res.data);
  
  console.log('====================================');
  
  setDataproducts(res.data);
  
  }).catch(error=>{
 console.log(error);
  
  })
  
  }



  const [productss_band, setDataproductsBrand] = useState([])

  const get_products_barnd=()=>{
  
    
    let ids=old;
  
    // let catagory=localStorage.getItem('catagoris');
    
    NodejsApi.get(`catass_brand/${ids}/${catagoy}/${brand}`)
    .then(res=>{
    console.log('====================================');
    
    console.log(res.data);
    
    console.log('====================================');
    
    setDataproductsBrand(res.data);
    
    }).catch(error=>{
   console.log(error);
    
    })
    
    }

















    





  const [catagori, setCatagori2] = useState();

  const setCatagoris =(brand)=>{
  
    setCatagori2(brand);
  
   localStorage.setItem('brand', brand);
  
  
  }
  














  const onClick = () => {
    Toast.show('top prdut sliders')
  }

  // const onHeaderClick = () => {
  //   Toast.show('点击了卡片Header区域')
  // }

  // const onBodyClick = () => {
  //   Toast.show('点击了卡片Body区域')
  // }


  const showToastMessage = () => {
    toast.success("Success Product add !", {
      position: toast.POSITION.TOP_CENTER,
    });
  }


  const showToastMessageEror = () => {
    toast.error("Error server !", {
      position: toast.POSITION.TOP_CENTER,
    });
  }











const Addcard=(id,price,offers,profits)=>{


const data={


  user_id:localStorage.getItem('id'),
  product_id:id,
  prices:price,
  offer:offers,
  profit:profits


}



LarveApi.post('order_card',data)
.then(res =>{


  showToastMessage();





}).catch(error => {
  showToastMessageEror();
console.log('====================================');
console.log('error ',error);
console.log('====================================');
  // usernavigates('/Login');
})






}



















const [searchQuery, setSearchQuery] = useState("");
const [filteredItems, setFilteredItems] = useState([]);

// const itemList = [
//   { name: "Apple", price: "$1.99" },
//   { name: "Banana", price: "$0.99" },
//   { name: "Orange", price: "$1.49" },
//   { name: "Strawberry", price: "$2.49" },
//   { name: "Grapes", price: "$3.99" },
//   { name: "Watermelon", price: "$4.99" },
// ];

const handleInputChange = (event) => {
  const query = event.target.value;
  setSearchQuery(query);
  const filtered = productss.filter((item) =>
    item.name.toLowerCase().includes(query.toLowerCase()) ||
    item.model.toLowerCase().includes(query.toLowerCase()) ||
    item.tag.toLowerCase().includes(query.toLowerCase()) 
  );
  setFilteredItems(filtered);
};

const handleSubmit = (event) => {
  event.preventDefault();
  console.log("Search query:", searchQuery);
  const filtered = productss.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())||
    item.model.toLowerCase().includes(searchQuery.toLowerCase())||
    item.tag.toLowerCase().includes(searchQuery.toLowerCase())
  );
  setFilteredItems(filtered);
};



// const handleInputChange = (event) => {
//   const query = event.target.value;
//   setSearchQuery(query);
//   const filtered = productss.filter((item) =>
//     item.name.toLowerCase().includes(query.toLowerCase())
//   );
//   setFilteredItems(filtered);
// };

// const handleSubmit = (event) => {
//   event.preventDefault();
//   console.log("Search query:", searchQuery);
//   const filtered = productss.filter((item) =>
//     item.name.toLowerCase().includes(searchQuery.toLowerCase())
//   );
//   setFilteredItems(filtered);
// };




const [pro_list, setstLIst] = useState('2')











const Catchdata=()=>{
  

    switch (pro_list) {
      case '1': return  <FormatAlignLeftIcon onClick={()=>setstLIst('2')} /> 
        
      case '4': return  <FilterListIcon onClick={()=>setstLIst('1')} /> 
      case '2': return  <ListIcon  onClick={()=>setstLIst('3')} /> 
      case '3': return  <ListIcon  onClick={()=>setstLIst('4')} /> 
      
    
      default:return <FormatAlignLeftIcon onClick={()=>setstLIst('2')} />; 
      
    }
 
  
}


















// const Modelk=()=>{
//   switch (dilotrue) {
//       case 'true':return <Dialog_product_dital />
//       case 'fal':return <h3>fal</h3>
          
       
  
//       default:return null
          
//   }
// }









const [productss_band_detail, setDataproductsBrand_detail] = useState([])



const [open, setOpen] = useState(false);
const [openId, setOpenId] = useState();



// const openId= () => {
//   setOpen(true);
// };
const [dilotrue, setsTrue] = useState('')
const [dilotrueID, setsTrueID] = useState()

const AddModel_product =(id,type)=>{
  setsTrueID(id);
  setsTrue(type);

  setOpen(true);













  
  let ids=id;

  // let catagory=localStorage.getItem('catagoris');
  
  NodejsApi.get(`catass_brand_getid/${ids}`)
  .then(res=>{
  console.log('ggggggggggggeeeeeeeeeeeeeeeettttttttt id====================================');
  
  console.log(res.data);
  
  console.log('====================================');
  
  setDataproductsBrand_detail(res.data);
  
  }).catch(error=>{
 console.log(error);
  
  })



  
}

const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};







// const [productss_band_detail, setDataproductsBrand_detail] = useState([])

// const get_products_barnd_Daitail=()=>{

  
//   let ids=dilotrueID;

//   // let catagory=localStorage.getItem('catagoris');
  
//   NodejsApi.get(`catass_brand_getid/${ids}`)
//   .then(res=>{
//   console.log('ggggggggggggeeeeeeeeeeeeeeeettttttttt id====================================');
  
//   console.log(res.data);
  
//   console.log('====================================');
  
//   setDataproductsBrand_detail(res.data);
  
//   }).catch(error=>{
//  console.log(error);
  
//   })
  
//   }




  return (
    <>
      
     




        
   

     {/* {Modelk()} */}

      <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}>
          
{Catchdata()}


            {/* {pro_list ?
        pro_list==1 ?(
<>
<FormatAlignLeftIcon onClick={()=>setstLIst(3)} />

</>
        ):
        (
          <>
          <FormatAlignLeftIcon onClick={()=>setstLIst(4)} />
          </>
        )
: */}


         
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            My-shopings.com
          </Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <form onSubmit={handleSubmit}>

            <StyledInputBase

value={searchQuery}
 onChange={handleInputChange}
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />

            </form>
          </Search>
        </Toolbar>
      </AppBar>
    </Box>









     

{/* <TopMnenu /> */}


<div className=' p-1 m-1'>


   

{/*     
        <Card title='卡片标题' onClick={onClick} />
   

        <Card onClick={onClick}>卡片内容</Card>
    

   
        <Card
          title={
            <div style={{ fontWeight: 'normal' }}>
              <AntOutline style={{ marginRight: '4px', color: '#1677ff' }} />
              卡片标题
            </div>
          }
          extra={<RightOutline />}
          onBodyClick={onBodyClick}
          onHeaderClick={onHeaderClick}
          
        >
          <div >卡片内容</div>
          <div  onClick={e => e.stopPropagation()}>
            <Button
              color='primary'
              onClick={() => {
                Toast.show('点击了底部按钮')
              }}
            >
              底部按钮
            </Button>
          </div>
        </Card>
     


        <Card
          headerStyle={{
            color: '#1677ff',
          }}
         
          title='卡片标题'
        >
          卡片内容
        </Card> */}




{/* 

        <ImageListItem>
          <img
         
            srcSet={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
            src={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
            alt={item.name}
            loading="lazy"
          />
          <ImageListItemBar
            title={item.catagori}
            subtitle={item.name}
            onClick={()=>setCatagoris(item.catagori)}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.model}`}
              >
                <InfoIcon />

              </IconButton>




             
            }


            
          />



        </ImageListItem> */}



{brand ? 


<>








<ImageList cols={pro_list}>




{searchQuery === "" ? (
          // brand 


          productss_band.map((item) => (


 







            <ImageListItem>
          
            <Badge.Ribbon text={`৳ `+item.pricee   +'/- '}>
               
              </Badge.Ribbon>
          
          
              {item.img ?
              
          
                <img
          
          style={{height:'190px', width:'100%'}}
             
             srcSet={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
             src={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
             alt={item.tag}
             loading="lazy"
           />
          
          
          
          
              :
          
          
          
          
          
              <img
          
          style={{height:'190px', width:'100%'}}
             
             srcSet={item.imglink}
             src={item.imglink}
             alt={item.tag}
             loading="lazy"
           />
          
              }
          
          
              <ImageListItemBar
                title={item.name}
                subtitle={item.model}
                onClick={()=>setCatagoris(item.brand)}
                actionIcon={
          
          
                  <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    aria-label={`info about ${item.model}`}
                  >
          
          
          <Badge
          
          className='m-2  text-white'
                  
                  count={<del>৳ {item.reprice}/-</del>}
                  style={{ backgroundColor: '#541a' }}
                />
          
          
          
          
                   <ShoppingCart   className='m-2  text-success' onClick={()=>Addcard(item.id, item.pricee, item.offerprice, item.profit)}  color='green' />
          
                   
          
          
          
                 
                   <InfoIcon   onClick={()=>AddModel_product(item.id,'true')} />
          
                  </IconButton>
          
          
          
          
                 
                }
          
                
                
              />
          

          
            </ImageListItem>
          
          
          
          ))


          // end brand 
        ) : filteredItems.length > 0 ? (
          



          filteredItems.map((item) => (


 







            <ImageListItem>
          
            <Badge.Ribbon text={`৳ `+item.pricee   +'/- '}>
               
              </Badge.Ribbon>
          
          
              {item.img ?
              
          
                <img
          
          style={{height:'190px', width:'100%'}}
             
             srcSet={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
             src={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
             alt={item.tag}
             loading="lazy"
           />
          
          
          
          
              :
          
          
          
          
          
              <img
          
          style={{height:'190px', width:'100%'}}
             
             srcSet={item.imglink}
             src={item.imglink}
             alt={item.tag}
             loading="lazy"
           />
          
              }
          
          
              <ImageListItemBar
                title={item.name}
                subtitle={item.model}
                onClick={()=>setCatagoris(item.brand)}
                actionIcon={
          
          
                  <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    aria-label={`info about ${item.model}`}
                  >
          
          
          <Badge
          
          className='m-2  text-white'
                  
                  count={<del>৳ {item.reprice}/-</del>}
                  style={{ backgroundColor: '#541a' }}
                />
          
          
          
          
                   <ShoppingCart   className='m-2  text-success' onClick={()=>Addcard(item.id, item.pricee, item.offerprice, item.profit)}  color='green' />
          
                    <InfoIcon />
          
          
          
                 
          
          
                  </IconButton>
          
          
          
          
                 
                }
          
          
                
              />
          
          
          
            </ImageListItem>
          
          
          
          ))



        ) : (
          <li>No matching items found</li>
        )}








    

</ImageList>














</>



:




<>




{/* <Card   className='m-2'    title={'top product'} onClick={onClick}>
          


          <Carousel autoplay>
      <div>
        <h3 > mobile </h3>
      </div>
      <div>
        <h3 >2</h3>
      </div>
      <div>
        <h3 >3</h3>
      </div>
      <div>
        <h3>4</h3>
      </div>
    </Carousel>




  
  
  
  
  
  
          </Card> */}

















          <ImageList cols={pro_list}>

    
{productss.map((item) => (


  <Link to={`/catagory/${item.catagori}/${item.old}/${item.brand}`} >
  <ImageListItem>



  {item.img ?
    
<Image
    


style={{height:'190px', width:'100%'}}
 
 srcSet={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
 src={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
 alt={item.tag}
 loading="lazy"
/>

  :

  <Image
 

 
style={{height:'190px', width:'100%', zIndex:9,}}

 srcSet={item.imglink}
 src={item.imglink}
 alt={item.tag}
 loading="lazy"


/>

  }


    <ImageListItemBar
      title={item.brand}
      subtitle={item.name}      onClick={()=>setCatagoris(item.brand)}
      actionIcon={
        <IconButton
        
          sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
          aria-label={`info about ${item.model}`}
        >
          

        </IconButton >




       
      }


      
    />



  </ImageListItem>

</Link>

))}
</ImageList>














</>

}











{/* 

  <List
    grid={{
      gutter: 10,
      column: 3,
    }}
    dataSource={data}
    renderItem={(item) => (
      <List.Item>
        <Card title={item.title}>Card content</Card>
      </List.Item>
    )}

  />
 */}








<React.Fragment>
    





   











    <Dialog


 
style={{ zIndex:50,color:'#fff'}}
      open={open}
      onClose={handleClose}

      aria-labelledby="alert-dialog-title"

      aria-describedby="alert-dialog-description"
    >






   



      <DialogContent className='bg-dark text-white'>
        <DialogContentText id="alert-dialog-description">
   

{productss_band_detail.map((item)=>

<>







        <Box sx={{ flexGrow: 1 }}>

        <Badge.Ribbon text={`৳ `+item.pricee   +'/- '}>
               
               </Badge.Ribbon>

      <Grid container spacing={2}>
        <Grid xs={8}>

      
      
        {item.img ?
    

    <Image


style={{height:'190px', width:'100%'}}
 
 srcSet={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
 src={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
 alt={item.tag}
 loading="lazy"
/>

  :

  <Image
 

 
style={{height:'190px', width:'100%'}}

 srcSet={item.imglink}
 src={item.imglink}
 alt={item.tag}
 loading="lazy"


/>

  }


        </Grid>
        <Grid xs={4} className='text-white'>
         <>
         <br/>
         <del>৳ {item.reprice}/-</del>
         <><p> {item.name}  </p></>  
          <><p> {item.model}  </p></>  
         
         </>
        </Grid>
        <Grid xs={4}>
        
        </Grid>
        <Grid xs={8}>
         
<>

<button className='btn'>
<Link to={`/Product-View/${item.uniqid}`}>more view</Link>
</button>

<button className='btn'>
<AddShoppingCartIcon   className='m-2  text-success ' onClick={()=>Addcard(item.id, item.pricee, item.offerprice, item.profit)}  color='green' />
</button>








</>

<p  className='text-white card p-3 bg-dark'> <h5> discription :-
  </h5>
       <pre>
        
       {item.discript}
        
        </pre>    
          </p>





        </Grid>
      </Grid>
    </Box>

    </>

  
)}


        </DialogContentText>

      </DialogContent>
      <DialogActions className='bg-dark'>
        <Button  className={'text-danger'} onClick={handleClose}>Cancle</Button>
       
      </DialogActions>
    </Dialog>

  </React.Fragment>




















        </div>


        <div>
          
<br/>
<br/>
<br/>
<br/>



        </div>

         <Buttons />



        
   
    </>
  )

        }


  export default Products_catagoris