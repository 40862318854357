
import NotificationsIcon from '@mui/icons-material/Notifications';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HomeIcon from '@mui/icons-material/Home';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import SendIcon from '@mui/icons-material/Send';


import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

import  React, { useEffect, useState }  from  "react";
import { BottomNavigation } from  "reactjs-bottom-navigation";
import Badge from '@mui/material/Badge';
import { useNavigate } from 'react-router-dom';



import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';



import CardMedia from '@mui/material/CardMedia';


import axios from 'axios'
import { Alert } from 'antd';








const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 450,
	overflow: 'auto',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
  };

function  Bottom_navbar() {




	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);



	const [modell, setModel] = useState(false)

useEffect(() => {


	setInterval(() => {
		userOrdrsall()
	}, 3000);
	


}, [])



const [allorders, setDAtaall] = useState([])

const [lengths, setLenght] = useState('')

const userOrdrsall=()=>{

	let id=localStorage.getItem('id');

	axios.get(`https://court.my-shopings.com/public/api/order_card_get/${id}`)
	.then(res=>{
	console.log(res.data);
	setDAtaall(res.data.data);
console.log('====================================');
console.log('this bottom menu alllllllllllllllll');
console.log(res.data.data.length);
	setLenght(res.data.data.length);
		
	}).catch(error=>{
		console.error(error);
		
	})

	
}





	const usenativs =useNavigate();

	const setalldataHome=(id)=>{
	
		usenativs('/');
	}

	const setalldataHome2=(id)=>{
	
		usenativs('/user-profles');
	}


	const Notificatio=()=>{

		// usenativs('/ordr_list_tusers');
	}
	

const Cardsshow=()=>{

	usenativs('/ordr_list_tusers');
}

   
const  bottomNavItems = [
	{
		title:  "Home",
		onClick: ({ id }) =>  setalldataHome(id),
		icon: <Badge badgeContent={0} max={99} color="secondary">    <HomeIcon />  </Badge>, // just for example
		activeIcon:  <Badge badgeContent={0} max={99} color="secondary">     <HomeIcon color="#fff" />  </Badge>
	},  

    {
		title:  "Profile",
		onClick: ({ id }) =>  setalldataHome2(id),
		icon: <Badge badgeContent={0} max={99} color="secondary">  <AccountCircleIcon />  </Badge> , // just for example
		activeIcon:  <Badge badgeContent={0} max={99} color="secondary">   <AccountCircleIcon color="#fff" /> </Badge> 
	},

	


    {
		
   
		onClick: ({ id }) => handleOpen(true),
		icon: 
  <OndemandVideoIcon    />
        , // just for example
		activeIcon:   <OndemandVideoIcon color="#fff"    /> 
	},


    {
		title:  "Notification",
   
		onClick: ({ id }) => Notificatio('notificaton'),
		icon:  <Badge badgeContent={0} max={99} color="secondary">
  <NotificationsIcon />
        </Badge>, // just for example
		activeIcon:  <Badge badgeContent={0} max={99} color="secondary">  <NotificationsIcon color="#fff" /> </Badge>
	},





    {
		title:  "shopings",
		onClick: ({ id }) =>  Cardsshow(id),
		icon:   <Badge badgeContent={lengths} max={99} color="secondary"> <ShoppingCartIcon   />  </Badge> , // just for example
		activeIcon: <Badge badgeContent={lengths} max={99} color="secondary">  <ShoppingCartIcon color="#fff" value='43234'   /> </Badge>,
        
	}


    // {
	// 	render: ({ isActive, id }) =>  isActive ? <strong>{id}</strong> : <span>{id}</span>,
	// },

	// items can have either title, icon or both or neither!
	
	
];

  

return (
<div>
	<BottomNavigation
		items={bottomNavItems}
		selected={2}
		onItemClick={(item) =>  console.log(item)}
		activeBgColor="green"
		activeTextColor="white"
        class='bg-info'
	/>


<div>
   
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
<div style={{height:'500px',}} className=''>




			<h4>About App</h4>
			
          <CardMedia>

		  <iframe width={'100%'} height={'100%'} src="https://www.youtube.com/embed/E3L05mwpUYA?si=Ao6QqEjc-qaxUs6R&amp;start=149" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>


		 
			</CardMedia>


			<h6>

			এখন থেকে প্রতিটি ব্যয় থেকে হবে আয় !

			</h6>
			
			<h6>
			লক্ষ লক্ষ টাকা পুরস্কার পাবে প্রতি মাসে !!
			</h6>
			
			


			<Alert

message=" আমাদের এখানে পাওয়া  যাবে নতুন পুরাতোন  প্রোডাক্ট  এবং  জামা কাপর, কসমিটিস , জুতা, পেন্ট, বই, ঔষুধ , কাচাঁবাজার  এবং আপনার পছন্দের রেডিমেট  খাবার  অর্ডার করুন এখুনি । । "

type="warning"

showIcon

closable
/>








			<Alert
      message ="!! টাকা উত্তোলন করার সময়  !!"
      showIcon
      description="টাকা উত্তোলন করার সময় অবশ্যই আপনার একাউন্টের নাম ও ঠিকানা  ন্যাশনাল এন আইডির সাথে মিল  রেখে ফিলাপ করবেন ।  অন্যথায় আপনার টাকা উত্তোলন অসম্পর্ণ হবে।
      "
	  type="success"
      
      closable
    />

			<Alert

      message="আইডির সাথে মিল রেখে নাম ও বয়স রেজিস্ট্রেশন করবেন !! অন্যথায় একাউন্টি অচল হইয়া যেতে পারে !"
      
      type="success"
      showIcon
     
      closable
    />


<Alert

      message=" আমাদের কোম্পানী থেকে  প্রোডাক্ট কিনলে অথবা কাউকে কিনে দিলে আপনি লভ্য অংশ পাবেন ।   !"
      
      type="success"
      showIcon
     
      closable
    />






<Alert

      message=" প্রতেক মাসে রাফ্রেল ড্রো হবে । "
      showIcon
      type="warning"
      action={<>
<img  alt='lottary tag' width={'100px'} height={'100px'} src={require('../logo/lotttars.png')} />
</>}

description="  সরাসরি ইউটিউব চ্যানেলে দেখানো হবে। "
     
      closable
    />




<Alert

      message=" আমাদের সর্ফ্টায়ার স্বয়ংক্রিয়ভাবে  Winner কে খুঁজে নিবে, আমাদের কোন হস্তখেপ নেই। "
      
      type="success"

      showIcon
     
      closable
    />




<Alert

      message=" আপনার অর্ডার কৃত প্রোডাক্ট  হাতে পাওয়ার পর আপনার একাউন্টে টাকা জমা হবে । "
      
      type="success"

      showIcon
     
      closable
    />



<marquee  class='marquee btn' title={'tile'}> বিশেষ দ্রষ্টব্য : আপনার একাউন্টের এক্সেস নিতে না পারলে আপনি আপনার কাগজ পত্র নিয়ে হেড অফিসে যোগাযোগ করুন ।।   </marquee>




</div>
			       </Box>

      </Modal>
    </div>

</div>
);

}


export default Bottom_navbar
