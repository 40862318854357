import React,{useEffect,useState} from 'react'

import { Col, Divider, Row } from 'antd';

import axios from 'axios'
import { Link } from 'react-router-dom';

// import './App.css';

const products = [
  {
    id: 1,
    name: 'Product 1',
    imageUrl: 'https://via.placeholder.com/150',
    description: 'Description of Product 1',
  },
  {
    id: 2,
    name: 'Product 2',
    imageUrl: 'https://via.placeholder.com/150',
    description: 'Description of Product 2',
  },
  {
    id: 3,
    name: 'Product 3',
    imageUrl: 'https://via.placeholder.com/150',
    description: 'Description of Product 3',
  },


  {
    id: 3,
    name: 'Product 3',
    imageUrl: 'https://via.placeholder.com/150',
    description: 'Description of Product 3',
  },



  {
    id: 3,
    name: 'Product 3',
    imageUrl: 'https://via.placeholder.com/150',
    description: 'Description of Product 3',
  },
];


function Product_home_page() {

//   const [products, setPeouctset] = useState([])

// useEffect(() => {


//   getproduct();
// }, [])



// const getproduct=()=>{




//   try {

//     axios.get('http://localhost:4000/product')
//     .then(res=>{
//       setPeouctset(res.data);
//       console.log('====================================fsdfsdf');
//       console.log(res.data);
//       console.log('====================================');
//     })
    
//   } catch (error) {

//     alert('errors');
    
//   }finally{

// console.log('====================================');
// console.log('finnaly ');
// console.log('====================================');

//   }


// }


const style=  { background: '#5f5fff', padding: '2px 0', margin:4 };


  return (
   <>



<Divider orientation="left">

my-shopings.com


</Divider>


    <Row gutter={16}>











{products.map(item =>(


<Col className="gutter-row  product-card" span={8}>
  <div style={style}>
  <Link to={`/products/${item.name}`} className='text-white'> 
  
  

  
  <p className='p-2'> {item.description}  </p> 
  <p className='p-2'> {item.id}  </p> 
  
  </Link>
  
    </div>
 

</Col>




))}

     
      
      
  


    </Row>




   </>



  )
}

export default Product_home_page





















// import React from 'react';
// import './App.css';

// const products = [
//   {
//     id: 1,
//     name: 'Product 1',
//     imageUrl: 'https://via.placeholder.com/150',
//     description: 'Description of Product 1',
//   },
//   {
//     id: 2,
//     name: 'Product 2',
//     imageUrl: 'https://via.placeholder.com/150',
//     description: 'Description of Product 2',
//   },
//   {
//     id: 3,
//     name: 'Product 3',
//     imageUrl: 'https://via.placeholder.com/150',
//     description: 'Description of Product 3',
//   },
// ];

// const ProductCard = ({ product }) => {
//   return (
//     <div className="product-card">
//       <img src={product.imageUrl} alt={product.name} />
//       <h2>{product.name}</h2>
//       <p>{product.description}</p>
//     </div>
//   );
// };

// function Product_home_page() {
//   return (
//     <div className="App">
//       <h1>3D Product Cards</h1>
//       <div className="product-container">
//         {products.map((product) => (
//           <ProductCard key={product.id} product={product} />
//         ))}
//       </div>
//     </div>
//   );
// }

// export default Product_home_page;
