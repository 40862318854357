
import React,{useState} from 'react'
import { Spin, Alert } from 'antd';
import Ulload from '../logo/my-app.png'

import './Register.css'
import { Link, useNavigate } from 'react-router-dom'



import axiosInstance from '../../Url/Urls';


import axios from 'axios';





function Login() {

    const usernagicetes=useNavigate();



const [name, setName] = useState('')
const [pass, setPass] = useState('')
const [pass0, setNewpass] = useState('')


const[error, setNameError]=useState()


    const loginpage=()=>{



if(name.length){


    if(pass.length){



                setNameError(
            <Spin tip="Loading...">
            <span>Loading...</span>
             </Spin>
        );


const data={
    name:name,
    pass:pass
}

try {
    
  
    axiosInstance.post(`login`,data)
    .then(response => {
      console.log(response.data.user)
   
  
      
console.log('====================================');
console.log(response.data.user);
console.log('====================================');
if(response.data.user.length==1){


console.log('====================================');
console.log('this login data fetch laravel');
console.log('====================================');

    localStorage.setItem('username',response.data.user[0].name);
    localStorage.setItem('id',response.data.user[0].id);
    localStorage.setItem('img',response.data.user[0].img);
    localStorage.setItem('uiqid',response.data.user[0].uniqid);

    usernagicetes('/');

    setNameError();

}else{

    setNameError('error pass/phone enter current !!');

}


     // Check for specific data conditions and handle accordingly
     if (response.data.error === 'some_error') {
        // Handle 'some_error'
      }
    })
    .catch(error => {
      console.log('Error this login option axios:', error.message);

      setNameError('error server site try agen');

    }); 





} catch (error) {
    alert('error catch get');

}









       
    }else{
       
setNameError('inclued password');

    }



}else{
    setNameError('inclued email or phone');


}

//       const datas={
//         name:name,
//         pass:pass,
//             }
// axios.post('https://update.program.my-shopings.com/signup',datas)
// .then(res => {
//   console.log(res.data);
// })



// axios.post('https://api.my-shopings.com/deleted', {
//     id:'10'
// })
// .then(function (response) {
//   console.log(response);
//   alert(response.data);
// })
// .catch(function (error) {
//   console.log(error);
//   alert('error axios');
// });




    }




  return (
    



<>

<div className='container'>

  <div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-body">
   
   







      <div class="wrapper" >
        <div class="logo">
            <img src={Ulload} alt="" />
        </div>
        <div class="text-center mt-4 name" >
            my-shopings.com
        </div>
        <div class="p-3 mt-3">
            <div class="form-field d-flex align-items-center">
                <span style={{color:'aqua'}} class="far fa-user"></span>
                <input   style={{color:'aqua'}} type="text" name="userName" max={11} onChange={e =>setName(e.target.value)}   id="userName" placeholder="phone/email" />
            </div>
            <div class="form-field d-flex align-items-center">
                <span style={{color:'aqua'}} class="fas fa-key"></span>
                <input type="password"  maxLength={20}  onChange={e =>setPass(e.target.value)} name="password" id="pwd" placeholder="Password" />
            </div>
        <p className='text-danger'>   {error} </p>




      






        </div>
        <button onClick={loginpage} class="btn mt-3">Login</button>
        <div class="text-center fs-6">
        <br/>

  
        <Link to={'/Forget-password'}> <a>Forget password?</a> </Link>  or   <Link to={'/Register'}> <a href="#sign-up" className=''   >new Register</a> </Link>
        </div>
    </div>









    </div>
  </div>
</div>

</div>















</>











  )
}

export default Login