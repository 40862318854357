import * as React from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import TextField from '@mui/material/TextField';
import { Empty, Input,message } from 'antd';

import { ToastContainer, toast } from 'react-toastify';


import Url_larav_api from '../../../Url/Urls'


import Urlnodejs from '../../../Url/Apinode'

export default function Change_password() {

    const [passwordVisible, setPasswordVisible] = React.useState(false);
    const [passwordVisible1, setPasswordVisible1] = React.useState(false);


    const rootRef = React.useRef(null);

const [errors, setText_error] = React.useState('')

    const notify = () => {
        toast.success("Successfully Password change ", {
          position: toast.POSITION.TOP_CENTER,
        });
      };



      const errorss22 = () => {
        toast.error(" password or email worng !! !", {
          position: toast.POSITION.TOP_CENTER,
        });
      };

      const errorss = () => {
        toast.error("Server network  Error !!!", {
          position: toast.POSITION.TOP_CENTER,
        });
      };




    const [messageApi, contextHolder] = message.useMessage();

    // const success = () => {
    //   messageApi.open({
    //     type: 'success',
    //     content: 'This is a success message',
    //   });
    // };
    // const error = () => {
    //   messageApi.open({
    //     type: 'error',
    //     content: 'This is an error message',
    //   });
    // };
    // const warning = () => {
    //   messageApi.open({
    //     type: 'warning',
    //     content: 'This is a warning message',
    //   });
    // };






const [email, setEmails] = React.useState()
const [pass, setpasswrod] = React.useState()
const [newpass, setpasswrod_New] = React.useState()


const sentpasswrod =()=>{

const data={

    email:email,
    pass:pass,
    newpass:newpass,
    action:'forget',

}


if(!email){

alert(' user current email address')


} else if(!pass){

  alert('enter current Account number ')
}
else if(!newpass){
  alert('enter new password ( mx-6 )')
  }
else{



Url_larav_api.post('change_passwrod_api',data)
    .then(res =>{
       

        if(res.data.data==1){




          console.log(res.data.data);

          console.log(' successfully change password');


            notify();

            

            setText_error(<p class='text-success'> password change success </p>);



        }else{

console.log(' else change pass ====================================');
console.log(res.data.data);
console.log('====================================');



setText_error(<p className="text-danger"> pass or email worng !!  </p>);

          console.log(res.data.data);
            errorss22();


        }

console.log('this a pasword change');

        console.log(res.data.data);

      



    })
    .catch(error=>{

      console.log(' errror ====================================', error);
    errorss();




    })






  }




}








  return (
    <Box
    class={'bg-dark'}
      sx={{
        height: 300,
        flexGrow: 1,
        minWidth: 300,
        transform: 'translateZ(0)',
        // The position fixed scoping doesn't work in IE11.
        // Disable this demo to preserve the others.
        '@media all and (-ms-high-contrast: none)': {
          display: 'none',
        },
      }}
      ref={rootRef}
    >
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open
    
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        sx={{
          display: 'flex',
          p: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
        container={() => rootRef.current}
      >
        <Box
        className='bg-dark'
          sx={{
            position: 'relative',
            width: 400,
          
            border: '2px solid #000',
            boxShadow: (theme) => theme.shadows[5],
            p: 4,
          }}
        >
          










  
     
          <div class="form-group">

            <h4 className='text-center' style={{color:'aqua'}}>Forget-password</h4>

{errors}

    <label for="exampleInputEmail1" className='p-1 text-white'>Email address</label>
    <input type="email" class="form-control"
    
    onChange={(e)=>setEmails(e.target.value)}
     id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
  </div>

  <div class="form-group">
    <label for="exampleInputPassword1" className='p-1 text-white'>current Account No : </label>

    <Input.Password
    display={'none'}
    class="form-control"
    onChange={(e)=>setpasswrod(e.target.value)}
          placeholder="current Account No : 5345345"
          visibilityToggle={{
            visible: true,
            onVisibleChange: setPasswordVisible,
          }}

        />

  </div>



  <div class="form-group">


    <label for="exampleInputPassword1" className='p-1 text-white'>New Password</label>

    <Input.Password
    class="form-control"
          placeholder="New password"
          min={6}
          max={10}
          maxLength={10}
          onChange={(e)=>setpasswrod_New(e.target.value)}
          visibilityToggle={{
            visible: passwordVisible1,
            onVisibleChange: setPasswordVisible1,
          }}
        />
   
  </div>

  <div class="form-check">
    



  </div>

  <button type="submit" onClick={sentpasswrod} class="btn btn-primary  container">Submit</button>
   <br/>
   <br/>
 
 <a className='btn bg-dark'  style={{color:'aqua'}}  href='/'>   home page</a>














        </Box>
      </Modal>
    </Box>
  );
}