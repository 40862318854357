import React, { useEffect, useState } from 'react';
import { UploadOutlined, UserOutlined, VideoCameraOutlined, CommentOutlined, CustomerServiceOutlined  } from '@ant-design/icons';
import { Layout, Popconfirm, Menu, theme,Carousel, Badge, FloatButton, Button, Result } from 'antd';


import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';


import FormLabel from '@mui/material/FormLabel';



// import  fWhatsAppIconrom '@mui/icons-material/WhatsApp';











import FacebookIcon from '@mui/icons-material/Facebook';

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCart from '@mui/icons-material/ShoppingCart'
import Box from '@mui/material/Box';
import {
WhatsappShareButton,
    FacebookShareButton,
    FacebookShareCount,
    HatenaShareCount,
    OKShareCount,
    PinterestShareCount,
    RedditShareCount,
    TumblrShareCount,
    VKShareCount,
  } from "react-share";


import getApinodenodejs from '../../../Url/Apinode'
import LarveApi from '../../../Url/Urls'


import { Image, Space } from 'antd-mobile'
import { useParams } from 'react-router-dom';
import { AssistantDirectionOutlined, CopyAll, Home, WhatsApp } from '@mui/icons-material';











// import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Modal } from '@mui/material';

// const actions = [
//   { icon: <FileCopyIcon />, name: 'Copy' },
//   { icon: <SaveIcon />, name: 'Save' },
//   { icon: <PrintIcon />, name: 'Print' },
//   { icon: <ShareIcon />, name: 'Share' },
// ];



import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';


const demoSrc =  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVOhJphtTEVDA6Um4-wneEhan560B6QmSat8FRyDN9_wc_XWQTqkUWOHALI1uvnuPyJQk&usqp=CAU";























const { Header, Content, Footer, Sider } = Layout;










// const items = [UserOutlined, VideoCameraOutlined, UploadOutlined, UserOutlined].map(
//   // (icon, index) => ({
//   //   key: String(index + 1),
//   //   icon: React.createElement(icon),
//   //   label: ` ${index + 1}`,
//   // }),
// );










const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };







  const showToastMessage = () => {
    toast.success("Success Product add !", {
      position: toast.POSITION.TOP_CENTER,
    });
  }


  const showToastMessageEror = () => {
    toast.error("Error server !", {
      position: toast.POSITION.TOP_CENTER,
    });
  }



  



const Product_view = () => {




  const [uname1, setNamee_gift1] = useState();
const [phone1, setNamee_gift11] = useState();
// const [email1, setNameegift111] = useState();
const [fulladdre1, setNameegift] = useState();





  const [uname, setNamee] = useState();
const [phone, setNamee1] = useState();
const [email, setNamee11] = useState();
const [fulladdre, setNamee111] = useState();






const [modelopent, setstate_Modaloepent] = useState(false)
const [autoparsess, setstate_Atoparsesst] = useState(false)


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);







const [state_loding, setstatLo_loding] = useState(false)



const rootRef = React.useRef(null);


const { uni, id}=useParams();

useEffect(()=>{


    Prodcutgetview();

},[])




  const { token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();



  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };






const [uniidget, setParamsget] = useState();



const [sta_Data, sets_DAta] = useState([])
const Prodcutgetview=()=>{






  // "uniqid": "321707",

    getApinodenodejs.get(`/view-product-all/${uni}`)

    .then(res =>{

        console.log('this url get product view');

        console.log(res.data);
        sets_DAta(res.data);
      
       

    })
    .catch(error =>{
      sets_DAta([]);
        console.log('====================================');
        console.log(error,'error page url');
        console.log('====================================');
    })


}

const [paymenname, setTpaymentname] = useState('');
const [payme_tectid, setTnamePayment_id] = useState('');




// http://sifatul.github.io/
const [state_id, setstate_NId_idprodcutid] = useState('');
const [state_id1, setstate_NId_id1] = useState('');
const [state_id3, setstate_NId_id3] = useState('');
const [state_id4, setstate_NId_id4] = useState('');
const [state_id5, setstate_NId_id5] = useState('');

const Addreadd =(iid,price,offers,profits) =>{

  setstate_NId_id1(id);
  setstate_NId_idprodcutid(iid);
  setstate_NId_id3(price);
  setstate_NId_id4(offers);
  setstate_NId_id5(profits);


  setstate_Modaloepent(true);


}













const Addcard2=(iid,price,offers,profits)=>{

  setstatLo_loding(true);
  const data={
  
    user_id:localStorage.getItem('id'),
    product_id: iid,
    prices:price,
    offer:offers,
    profit:profits
  
  
  }
  
  // setstate_Modaloepent(true);
  
  LarveApi.post('order_card',data)
  .then(res =>{
  

    showToastMessage();
    setstatLo_loding(false)
 
    console.log('dATA====================================');
    console.log(res.data);
    console.log('====================================');
  
  
  
  
  }).catch(error => {

    showToastMessageEror()
  
  console.log('====================================');
  console.log('error ',error);
  console.log('====================================');
    // usernavigates('/Login');
  })
  
  
  
  

  
  
  
  }





const submishare_product_add=(id,price,offers,profits)=>{





  
}
















  
  

const Addcard=()=>{

  setstatLo_loding(true);



  const data={
  


  
    user_id:id, 
    product_id:state_id,
    prices:state_id3,
    offer:state_id4,
    profit:state_id5,


name:uname,
payment:value,
phone:phone,
address:fulladdre,

giftname:uname1,
giftphone:phone1,
giftaddress:fulladdre1,
paymenttype:paymenname,
paytype:payme_tectid,



    
  
  
  }

{/* <li>  payment : {value}  </li>
<li>     name : {uname}  </li>
<li>    phone : {phone}  </li>
<li>  address : {fulladdre}  </li>
<li>    email : {email}  </li> */}
  // setstate_NId_id1(id);
  // setstate_NId_idprodcutid(iid);
  // setstate_NId_id3(price);
  // setstate_NId_id4(offers);
  // setstate_NId_id5(profits);
  
  setstate_Modaloepent(true);



  

  LarveApi.post('order_card_share_button',data)
  .then(res =>{
    

   
    setTimeout(() => {
     
      setstatLo_loding(false);
      setstate_Modaloepent(false);

      setstate_Atoparsesst(true);


      setTimeout(() => {
        setstate_Atoparsesst(false);


        
      }, 4000);


      setTimeout(() => {
        window.location.reload();
        
      }, 5000);

    }, 3000);
    console.log('dATA====================================');
    console.log(res.data);
    console.log('====================================');
  
  
  




  
    
  }).catch(error => {


    
    setTimeout(() => {
      showToastMessageEror();
   
    
   
      setTimeout(() => {
        window.location.reload();
        
      }, 2000);
 

    }, 2000);

   
  console.log(error);
  console.log(error);
  console.log(error);
  console.log('====================================');
  console.log('error ',error);
  console.log('====================================');
    // usernavigates('/Login');
  })
  
  
  
  
  
  
  }









  

const Addcard_add22=(id,price,offers,profits)=>{

  setstatLo_loding(true);
  const data={
  
  
    user_id:localStorage.getItem('id'),
    product_id:id,
    prices:price,
    offer:offers,
    profit:profits
  
  
  }
  
  setstate_Modaloepent(true);
  
  LarveApi.post('order_card',data)
  .then(res =>{
  
    setstatLo_loding(false)
 
    console.log('dATA====================================');
    console.log(res.data);
    console.log('====================================');
  
  
  
  
  }).catch(error => {
  
  console.log('====================================');
  console.log('error ',error);
  console.log('====================================');
    // usernavigates('/Login');
  })
  
  
  
  
  
  
  }











  
  const [copy, setStat_copyte] = useState();
  
  











  const [value, setValue] = React.useState('Cash on delivery');

  const handleChange = (event) => {
    setValue(event.target.value);
  };


  
const steps = [
  {

    
    label: 'shiping address',
    description: <>
    
    <input type='name' onChange={(e)=>setNamee(e.target.value)} className='form-control p-1 m-2' placeholder=' name'  />
    <input type='name' onChange={(e)=>setNamee1(e.target.value)} className='form-control p-1 m-2' placeholder=' Phone'  />
    <input type='name' onChange={(e)=>setNamee11(e.target.value)} className='form-control p-1 m-2' placeholder=' email'  />
    <input type='name' onChange={(e)=>setNamee111(e.target.value)} className='form-control p-1 m-2' placeholder=' full address'  />


  
    
    
    
    </>,
  },
  {
    label: 'payment option',
    description:
    <FormControl>
   
    <RadioGroup
      aria-labelledby="demo-controlled-radio-buttons-group"
      name="controlled-radio-buttons-group"
      value={value}
      onChange={handleChange}
    >
      <FormControlLabel value="Cash on delivery" control={<Radio />} label="Cash on delivery" />
      <FormControlLabel value="gift" control={<Radio />} label="gift product" />
    </RadioGroup>


<>


{value =='gift' ?

<>










<li>Bkash or Nagad : - 01784228999</li> {`(parsonal)`}

<input type='name' onChange={(e)=> (e.target.value)} className='form-control p-1 m-2' placeholder='Gift name'  />
    <input type='name' onChange={(e)=>setNamee_gift11(e.target.value)} className='form-control p-1 m-2' placeholder='Gift Phone'  />
    {/* <input type='name' onChange={(e)=>setNameegift111(e.target.value)} className='form-control p-1 m-2' placeholder=' email'  /> */}
    <input type='name' onChange={(e)=>setNameegift(e.target.value)} className='form-control p-1 m-2' placeholder='Gift full address'  />


    <br/> 




    <p>payment </p>




   <select onChange={(e)=>setTpaymentname(e.target.value)} className='form-control m-2'>

  <option value={'efaultChecked'} defaultChecked>select payment</option>
  <option value={'bkash'}>Bkash</option>
  <option value={'Nagad'}>Nadad</option>

 
   </select>
    <input type='text' onChange={(e)=>setTnamePayment_id(e.target.value)} className='form-control p-1 m-2' placeholder='money TransId id input '   />






</>
:

<>

</>
}



</>

  </FormControl>,
  },
  {


    label: 'info check ',
    description: <>

<ol>

<li>  payment : {value}  </li>
<li>     name : {uname}  </li>
<li>    phone : {phone}  </li>
<li>  address : {fulladdre}  </li>
<li>    email : {email}  </li>

</ol>





    </>,
  },
];



  return (
    <Layout  className='bg-dark' theme='dark'>
















      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
      
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <div className="demo-logo-vertical" />
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['4']} >
        <br/>
        <br/>
        <br/>
        <pre>
        এখন থেকে
        প্রতিটি ব্যয়
         থেকে হবে
           আয় 

        </pre>
   


        </Menu>
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        />
        <Content
        className='bg-dark'
          style={{
            
            margin: '24px 16px 0',
          }}
        >


          <div
          className='bg-dark'
            style={{
              padding: 24,
              minHeight: 360,
             
              borderRadius: borderRadiusLG,
            }}
          >
           


{/* contain dispaly app product view */}
<>






      {/* <div className='col-xs col-sm'>


      <div className='col-xs col-sm'>



      <div class="container mt-3">
 
  <div class="d-none bg-success">Hidden DIV</div>
  <div class="d-sm-none bg-success">d-sm-none</div>
  <div class="d-md-none bg-success">d-md-none</div>
  <div class="d-lg-none bg-success">d-lg-none</div>
  <div class="d-xl-none bg-success">d-xl-none</div>

  <div class="d-xxl-none bg-success">d-xl-none</div>

</div>
        </div>








      </div> */}







 
<div class="container-fluid mt-3">





  <div class="row">
    <div class="col-sm-4  text-white">

    <Carousel effect="fade">
    {sta_Data.map((item,inde)=>(

<>

<div className='' style={{boxShadow:'33px 33px 33px red'}}>
{item.img ?
        
    
        <img
    style={{ width:'100%'}}
     srcSet={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
     src={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
     alt={item.tag}
     loading="lazy"
    />
    
      :
    
      <img
     
    
    
     style={{ width:'100%'}}
    
     srcSet={item.imglink}
     src={item.imglink}
     alt={item.tag}
     loading="lazy"
    />
    
      }
    
    
</div>




</>



))}

</Carousel>
    </div>
    <div class="col-sm-8  bg-dark text-white shadow-lg">





    {sta_Data.map((item,inde)=>(




<Box class='shadow-lg bd-purple-800'>

<Badge.Ribbon text={`৳ `+item.pricee   +'/- '}>
       
       </Badge.Ribbon>

{/* <Grid container spacing={2}>
<Grid xs={8}> */}


{/* 
{item.img ?


<Image



style={{height:'190px', width:'100%'}}

srcSet={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
src={`https://court.my-shopings.com/public/uploads_product/${item.img}`}
alt={item.tag}
loading="lazy"
/>

:

<Image



style={{height:'190px', width:'100%'}}

srcSet={item.imglink}
src={item.imglink}
alt={item.tag}
loading="lazy"


/>

} */}


{/* </Grid>
<Grid xs={4} className='text-white'> */}
 <div className='p-4'>
 <br/>
 <del>৳ {item.reprice}/-</del>
 <><p> {item.name}  </p></>  
  <><p> {item.model}  </p></>  
 

{/* </Grid>
<Grid xs={4}>

</Grid>
<Grid xs={8}> */}
 
<>


{id ?
  <>

  <Button type="primary"   onClick={()=>Addreadd(item.id, item.pricee, item.offerprice, item.profit)} >
  By Now <AddShoppingCartIcon   className=' text-dark ' />
        </Button>
</>
:

<>
<Button type="primary" loading={state_loding}  onClick={()=>Addcard2(item.id, item.pricee, item.offerprice, item.profit)} >
     <AddShoppingCartIcon   className=' text-dark ' />
        </Button>

</>
}



{/* <button className='btn'>
<AddShoppingCartIcon   className='m-2  text-success ' onClick={()=>Addcard(item.id, item.pricee, item.offerprice, item.profit)}  color='green' />
</button> */}








</>

<p  className='text-white card p-3 bg-dark'> <h5> discription :-
</h5>
<pre>

{item.discript}

</pre>    
  </p>

</div>


{/* 
</Grid> */}


</Box>






))}

{/* <Box sx={{ height: 330, transform: 'translateZ(0px)', flexGrow: 1 }}>
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={handleClose}
          />
        ))}
      </SpeedDial>
    </Box> */}



    </div>
  </div>
</div>

</>



{/* END contain dispaly app product view */}
{/* END contain dispaly app product view */}
{/* END contain dispaly app product view */}
{/* END contain dispaly app product view */}
{/* END contain dispaly app product view */}
{/* END contain dispaly app product view */}
{/* END contain dispaly app product view */}
{/* END contain dispaly app product view */}
{/* END contain dispaly app product view */}
{/* contain dispaly app product view */}
{/* contain dispaly app product view */}
{/* contain dispaly app product view */}
{/* contain dispaly app product view */}
{/* contain dispaly app product view */}
{/* contain dispaly app product view */}
{/* contain dispaly app product view */}
{/* contain dispaly app product view */}
{/* contain dispaly app product view */}







{! id ?

<>



<FloatButton.Group
      trigger="hover"
     className='bg-info'
      style={{
        right: 19,
        marginBottom:80,
      }}
      href='/'
      tooltip='home page'
      icon={<Home />}
    >




    </FloatButton.Group>






<FloatButton.Group
      trigger="hover"
      type="primary"
      style={{
        right: 19,
      }}
      tooltip='share link, facebook, Whatsapp, more share app,  '
      icon={<ShareIcon />}
    >
      <FloatButton />



<WhatsappShareButton
        discript={'Whatsapp'}
            url={`https://my-shopings.com/Product-View/${uni}/${localStorage.getItem('id')}`}
            
        >

    
<FloatButton tooltip={'WhatwApp Share '} icon={<WhatsApp />} />
    


</WhatsappShareButton>


      <FacebookShareButton 
        discript={'vie'}
            url={`https://my-shopings.com/Product-View/${uni}/${localStorage.getItem('id')}`}
            
        >

      <FloatButton tooltip={'Facebook Share'} icon={<FacebookIcon />} />




    
</FacebookShareButton>


<CopyToClipboard text={`https://my-shopings.com/Product-View/${uni}/${localStorage.getItem('id')}`}
          onCopy={() => setStat_copyte(`https://my-shopings.com/Product-View/${uni}/${localStorage.getItem('id')}`)}>
           <FloatButton tooltip={
            
            copy ?
<span className='text-success'>copy </span>
            :
<span className='text-danger'> link copy</span>
           } icon={<CopyAll />} />
        </CopyToClipboard>




     






    </FloatButton.Group>
 
</>


:

<>

  
</>

}







{/* <Popconfirm
        title="Delete the task"
        description="Are you sure to delete this task?"
        open={true}
        // onOpenChange={handleOpenChange}
        // onConfirm={confirm}
        // onCancel={cancel}
        okText="Yes"
        cancelText="No"
      >
        
      </Popconfirm> */}



<Modal

disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={autoparsess}

        onClose={()=>setstate_Modaloepent(false)}

        
  
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        sx={{
          display: 'flex',
          p: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
        container={() => rootRef.current}
      >
        <Box
          sx={{
            position: 'relative',
            width: 300,
            
          }}
        >

<Result
style={{background:''}}
className='bg-dark text-white'
    status="success"
    title="Successfully Purchased "
   
    // extra={[
    //   <Button type="primary" key="console">
    //     Go Console
    //   </Button>,
    //   <Button key="buy">Buy Again</Button>,
    // ]}
  />

</Box>

  </Modal>


          </div>

          <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={modelopent}

        onClose={()=>setstate_Modaloepent(false)}
        
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        sx={{
          display: 'flex',
          p: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
        container={() => rootRef.current}
      >
        <Box
          sx={{
            position: 'relative',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: (theme) => theme.shadows[5],
            p: 4,
          }}
        >
 <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption">shiping product</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? <span className='text-success  ' onClick={Addcard}> Done</span> : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All Processing......  </Typography>
          <Button  className='text-primary bg-black' loading onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
           
          </Button>
        </Paper>
      )}
    </Box>

       </Box>
      </Modal>


      



        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >





{/* <div className='bg-red'>
        <FacebookShareButton 
        discript={'vie'}
            url={`https://my-shopings.com/Product-View/${uni}`}
            
        >
            okkk<AssistantDirectionOutlined style={{ opacity: '0.5'}} size={18} />
        </FacebookShareButton>
    </div> */}


           <a href='/'>  my-shopings.com</a> © {new Date().getFullYear()} Created by Md Rashidul Islam
        </Footer>













   








      </Layout>
    </Layout>
  );
};


export default Product_view