

// import React from 'react'

// function Wallet() {
//   return (
//     <div>Walle sdsdt</div>
//   )
// }


// export default Wallet

import React,{useState,useEffect} from 'react'

 
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import RoomPreferencesSharpIcon from '@mui/icons-material/RoomPreferencesSharp';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import NoteAltIcon from '@mui/icons-material/NoteAlt';

import PhonelinkLockIcon  from '@mui/icons-material/PhonelinkLock';

import VpnLockIcon from '@mui/icons-material/VpnLock';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';



import Laravelapi from '../../../Url/Urls'
import Urlnodej from '../../../Url/Apinode'

import VerifiedIcon from '@mui/icons-material/Verified';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import Settings from '@mui/icons-material/Settings'

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
// import { SetOutline, UserAddOutline } from 'antd-mobile-icons'
// import { CheckCircleTwoTone, HeartTwoTone, BellOutlined, UserAddOutlined, UserOutlined, DatabaseFilled } from '@ant-design/icons';

// import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
// import TrendingUpIcon from '@mui/icons-material/TrendingUp';


// import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

// import PaymentIcon from '@mui/icons-material/Payment';

// import Balanc_carts from './Carts/Balanc_carts'

import { Avatar, Col, Modal, Row, Tabs, Spin, Empty,Button, Alert  } from 'antd'



// import { Button } from 'antd/es/radio'
import { Link, useNavigate } from 'react-router-dom';
// import locale from 'antd/es/date-picker/locale/en_US';
import { Divider } from 'antd-mobile';
import { CircularProgress } from '@mui/material';
// import { CheckCircleOutline } from '@mui/icons-material';







{/* <BellOutlined   ></BellOutlined> */}


const TabPane = Tabs.TabPane;














function Settions() {

  let username=localStorage.getItem('username');

  const [expanded, setExpanded] = useState(false);
  // const [expanded, setExpanded] = useState(false);
  const [model, setExpandeMOdel] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const navitaon=useNavigate();
const Handelrclo=()=>{
  setExpandeMOdel(true);
}

const HandelrcloClool=()=>{
  setExpandeMOdel(false);
}





useEffect(() => {
  uergetDataall();

}, [])







  const [checked, setChecked] = useState(['wifi']);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };




  const [profilimg, setDefailimg] = useState(null)


const imgfile=(e)=>{
  setDefailimg(e.target.files[0]);

}


let ids=localStorage.getItem('id');
const formDate=new FormData();

formDate.append('profile', profilimg);
formDate.append('ids', ids);
formDate.append('status', 'img_profile');


const [loadingtrue, setloaLngtrue] = useState()
const uploadimgprofile=()=>{
    setloaLngtrue( <Button loading>loading...
        </Button>);

  Laravelapi.post('Profile-upload-admin', formDate)

  .then(res =>{


    if(res.data.data=='complete'){
        setloaLngtrue(<VerifiedIcon className='text-success' />)
        setInterval(() => {
          navitaon('/Login');
        }, 3000);
       
    }else{
        setloaLngtrue(<SyncProblemIcon className='text-warning'/>)
    }


  })

  .catch(error =>{
    setloaLngtrue(<SyncProblemIcon className='text-danger'/>)
  

  })

}

const [name, setNames] = useState('');
const [naNam, setName44s] = useState('');
const [name1, setNames11] = useState('');
const [name3, setNames33] = useState('');

const [account, setAccont] = useState('')
const [pass, setPas] = useState('')
const [uerdatain, setget_user] = useState([])

const uergetDataall=()=>{
  let id=localStorage.getItem('id');
    
  Urlnodej.get(`get_user_data/${id}`)
  .then(res =>{
    setget_user(res.data[0])
    setNames(res.data[0].name);
    setName44s(res.data[0].phone);
    setNames11(res.data[0].email);
    setNames33(res.data[0].address );
    console.log(res.data);
    
    console.log('get profile data all order ');
  })
  .catch(error =>{

    setget_user([]);
    console.log(' get order li ====================================');
    console.log(error);
    console.log('====================================');
  })
  

}
const [loadings, setspin] = useState();



const OKdataall=()=>{
 
  if(account==localStorage.getItem('uiqid')){

    const datall={
      name:name,
      phone:naNam, 
      email:name1,
      address:name3,
      id:localStorage.getItem('id'),
      pass:pass,
      account:account,
      
      }
      let id=localStorage.getItem('id');
    setspin( <CircularProgress style={{fontSize:'30px',position:'absolute',}}/>);

    Urlnodej.put(`get_user_data_update/${id}/${name}/${name1}/${naNam}/${name3}/${account}/${pass}`)



    .then(re=>{

      if(re.data=='success-user-update'){
        setspin(<CheckCircleIcon  />);
      }else{
        setspin(<WarningIcon />);
      }
      
      console.log('update get u====================================');
      console.log(re.data);
   
    }).catch(error=>{
      setspin(<WarningIcon className='text-danger' />);
      console.log('update get uerror error errror====================================');
      console.log(error);
    
    })


  










 
  }else if(!pass.length){
    setspin(<p className='text-danger'>Emty password</p>)
    

  }
  else{
    setspin(<p className='text-danger'>Account number woring</p>)
  }
  




}
  return (
   <>
<div  style={{background:'#1e2535',color:'aqua'}} className='p-3 container' >








<div className='container card p-2'  style={{background:'#1e2535',  boxShadow:' inset white 1px 1px 6px'}} >











<Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className='' style={{background:'#1e2535',color:'aqua'}} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}  >
<Settings />     


         settings     
         

         
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}>genarel settings</Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           






<>





<List
      sx={{ width: '100%', maxWidth: 360, bgcolor: '#1e253' }}
      // subheader={}
    >



    <Link to={'/password_change'}>
      <ListItem>
        <ListItemIcon className='btn'>
    
   
        <PhonelinkLockIcon  style={{color:'aqua'}} />

        </ListItemIcon>
        <ListItemText id="switch-list-label-wifi"   primary="Password Change" />


<VpnLockIcon  style={{color:'aqua'}} />

        {/* <Switch
          edge="end"
          onChange={handleToggle('wifi')}
          checked={checked.indexOf('wifi') !== -1}
          inputProps={{
            'aria-labelledby': 'switch-list-label-wifi',
          }}
        /> */}


      </ListItem>


</Link>

      {/* <ListItem>
        <ListItemIcon>
          <BluetoothIcon />
        </ListItemIcon>
        <ListItemText id="switch-list-label-bluetooth" primary="Bluetooth" />
        <Switch
          edge="end"
          onChange={handleToggle('bluetooth')}
          checked={checked.indexOf('bluetooth') !== -1}
          inputProps={{
            'aria-labelledby': 'switch-list-label-bluetooth',
          }}
        />
      </ListItem> */}


    </List>










</>




          </Typography>
        </AccordionDetails>
      </Accordion>















      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{background:'#1e2535',color:'aqua'}} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
          
          <ManageAccountsIcon  />
      
                              Users 
          
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}>
            You are currently not an owner
          </Typography> */}
        </AccordionSummary>



        <AccordionDetails>
       

        <p className='btn text-primary' >
Change profle img

</p>

<input type='file'  onChange={imgfile} className='btn' placeholder='changer profle img' />

<Button onClick={uploadimgprofile}  className='btn  text-info btn-dark'>upload  ... {loadingtrue}</Button>





        </AccordionDetails>
      </Accordion>







      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} style={{background:'#1e2535',color:'aqua'}} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}

          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
          <RoomPreferencesSharpIcon />
          
          
              Advanced settings
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}>
            Filtering has been entirely disabled for whole web server
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Button className='btn btn-success' onClick={Handelrclo} > <NoteAltIcon /> Profile Edit</Button>
        </AccordionDetails>
      </Accordion>




      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} style={{background:'#1e2535',color:'aqua'}} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
          
          <PermContactCalendarIcon />
          
          Personal data</Typography>
        </AccordionSummary>
        <AccordionDetails>
          

<table className='table'>
  <tr>
    <th>Account number :</th>
    <th>{uerdatain.uniqid}</th>
  </tr>
<tr>
  <th>
userName  :
  </th>
  <th>{uerdatain.name}</th>
</tr>

<tr>
  <th>
Mobile No  :
  </th>
  <th>{uerdatain.phone}</th>
</tr>

<tr>
  <th>
email  :
  </th>
  <th>{uerdatain.email}</th>
</tr>

<tr>
  <th>
Address   :
  </th>
  <th>{uerdatain.address}</th>
</tr>

</table>





        </AccordionDetails>
      </Accordion>







      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} style={{background:'#1e2535',color:'aqua'}} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
          
          <InfoSharpIcon  />
          
          About us</Typography>
        </AccordionSummary>
        <AccordionDetails>
         




<div>


        <h6>

এখন থেকে প্রতিটি ব্যয় থেকে হবে আয় !

</h6>

<h6>
লক্ষ লক্ষ টাকা পুরস্কার পাবে প্রতি মাসে !!
</h6>




<Alert

message=" আমাদের এখানে পাওয়া  যাবে নতুন পুরাতোন  প্রোডাক্ট  এবং  জামা কাপর, কসমিটিস , জুতা, পেন্ট, বই, ঔষুধ , কাচাঁবাজার  এবং আপনার পছন্দের রেডিমেট  খাবার  অর্ডার করুন এখনি । । "

type="warning"

showIcon

closable
/>








<Alert
message ="!! টাকা উত্তোলন করার সময়  !!"
showIcon
description="টাকা উত্তোলন করার সময় অবশ্যই আপনার একাউন্টের নাম ও ঠিকানা  ন্যাশনাল এন আইডির সাথে মিল  রেখে ফিলাপ করবেন ।  অন্যথায় আপনার টাকা উত্তোলন অসম্পর্ণ হবে।
"
type="success"

closable
/>

<Alert

message="আইডির সাথে মিল রেখে নাম ও বয়স রেজিস্ট্রেশন করবেন !! অন্যথায় একাউন্টি অচল হইয়া যেতে পারে !"

type="success"
showIcon

closable
/>


<Alert

message=" আমাদের কোম্পানী থেকে  প্রোডাক্ট কিনলে অথবা কাউকে কিনে দিলে আপনি লভ্য অংশ পাবেন ।   !"

type="success"
showIcon

closable
/>






<Alert

message=" প্রতেক মাসে রাফ্রেল ড্রো হবে । "
showIcon
type="warning"
action={<>
<img  alt='lottary tag' width={'100px'} height={'100px'} src={require('../../logo/lotttars.png')} />
</>}

description="  সরাসরি ইউটিউব চ্যানেলে দেখানো হবে। "

closable
/>




<Alert

message=" আমাদের সর্ফ্টায়ার স্বয়ংক্রিয়ভাবে  Winner কে খুঁজে নিবে, আমাদের কোন হস্তখেপ নেই। "

type="success"

showIcon

closable
/>




<Alert

message=" আপনার অর্ডার কৃত প্রোডাক্ট  হাতে পাওয়ার পর আপনার একাউন্টে টাকা জমা হবে । "

type="success"

showIcon

closable
/>



<marquee  class='marquee btn' title={'tile'}> বিশেষ দ্রষ্টব্য : আপনার একাউন্টের এক্সেস নিতে না পারলে আপনি আপনার কাগজ পত্র নিয়ে হেড অফিসে যোগাযোগ করুন ।।   </marquee>




</div>










        </AccordionDetails>
      </Accordion>




{/* 

      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} style={{background:'#1e2535',color:'aqua'}} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>About us 16</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
            amet egestas eros, vitae egestas augue. Duis vel est augue.
          </Typography>
        </AccordionDetails>
      </Accordion>











      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')} style={{background:'#1e2535',color:'aqua'}} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>About us 17</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
            amet egestas eros, vitae egestas augue. Duis vel est augue.
          </Typography>
        </AccordionDetails>
      </Accordion>



      <Accordion expanded={expanded === 'panel18'} onChange={handleChange('panel18')} style={{background:'#1e2535',color:'aqua'}} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>About us 18</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
            amet egestas eros, vitae egestas augue. Duis vel est augue.
          </Typography>
        </AccordionDetails>
      </Accordion> */}



















      </div>


      <Modal
        
      bgcolor={'black'}
      
        open={model}
        
        onClose={()=>setExpandeMOdel(false)}
        
    className={'bg-dark'}
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        sx={{
          display: 'flex',
          p: 1,
          alignItems: 'center',
          justifyContent: 'center',
          
        }}
        onCancel={()=>setExpandeMOdel(false)}
        footer='edit'
      >
      
          










  
     
          <div class="card bg-dark">

            <h4 className='text-center p-3' style={{color:'aqua'}}>edit profile</h4>


<div className='form-data p-4'>
  <input className='form-control m-1 p-1' value={name}  onChange={(e)=>setNames(e.target.value)} placeholder='Name' />
  <input className='form-control m-1 p-1' value={name1} onChange={(e)=>setNames11(e.target.value)} placeholder='email' />
  <input className='form-control m-1 p-1' value={name3} onChange={(e)=>setNames33(e.target.value)} placeholder='address' />
  <input className='form-control m-1 p-1' value={naNam} onChange={(e)=>setName44s(e.target.value)} placeholder='Phone' />
 
  <Divider
          style={{
            color: '#fff',
            borderColor: '#1677ff',
            borderStyle: 'dashed',
          }}
        >
         current Password and Account Number

        </Divider>

        <input className='form-control m-1 p-1'  onChange={(e)=>setAccont(e.target.value)} placeholder='Account number' />
  <input className='form-control m-1 p-1'  onChange={(e)=>setPas(e.target.value)} placeholder='current password' />
 
  <button className='btn btn-info container' onClick={OKdataall} >Submit {loadings}</button>
</div>




</div>









     
      </Modal>







</div>

   </>
  )
}

export default Settions

