// import React from 'react';
// import { Steps } from 'antd';
// const description = 'This is a description.';
// const items = [
//   {
//     title: 'Finished',
//     description,
//   },
//   {
//     title: 'In Progress',
//     description,
//   },
//   {
//     title: 'Waiting',
//     description,
//   },
// ];
// const App = () => (
//   <>
    
//     <Steps current={1} percent={80} labelPlacement="vertical" items={items} />
    
//   </>
// );
// export default App;








import React, { useState, useEffect } from 'react';
import { DeleteOutline, EyeInvisibleFill } from 'antd-mobile-icons'
import { Button, message, Popconfirm, Steps, theme,  Radio, RadioChangeEvent, Flex, Spin,  } from 'antd';
import axios from 'axios';
import { Divider, DotLoading, Form, Input, Space } from 'antd-mobile'



import UrlnodeJsapi from '../../Url/Apinode'
import Larvelapi from '../../Url/Urls'



import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


import SignalCellularConnectedNoInternet4BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet4Bar';


import { LoadingOutlined } from '@ant-design/icons';



import NewReleasesIcon from '@mui/icons-material/NewReleases';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import VerifiedIcon from '@mui/icons-material/Verified';



import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';






import Orldritetable from './Product_table/Product_table'
import { useNavigate } from 'react-router-dom';




const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// const [loginss, setLogins] = useState(true);


const Orldrtime2=()=>{


    useEffect(() => {


    
    }, [])




    return (
        <>



<><h3>this m22</h3></>




        </>
    )
}





const Orldrtime3=({data})=>{











    useEffect(() => {


    
    }, [])




    return (
        <>


<div className='container p-4'>



{/* {data==true ?  

<>

  <h3>true</h3>
</>

:
<>

  <h3>false</h3>
</>

} */}
<Flex gap="large"  className='p-4'   vertical>

  

{/* {data}
    

<Spin tip="Order Prosesing...." className='text-center' size="large">
  <div   className="content text-center" />
</Spin>




<h6 className='p-1'>  */}
{/* <button  className='btn'> <a href='/'>Home page</a>   </button></h6> */}


<h4>

order done click now finish prosess....

</h4>
</Flex>






</div>
        </>
    )
}





const Orldrtime=()=>{


  const [loadingss, setLoading] =useState();









const [value4545, setValue] = useState('payment method');

const onChange = (e) => {
  console.log('radio checked', e.target.value);
  setValue(e.target.value);
};





const vlauerados=(value4545)=>{
  switch (value4545) {
   case '1':return <h3>this order oi</h3>;
   case '2':return <h3>this order 2222</h3>;
     
   
  
   default: return <Gift_infomatons />;
    
  }
 }













const Gift_infomatons=()=>{




  const [divshow, setDivshow] = useState(<KeyboardBackspaceIcon />);



 
  const [iconlogins, setLoadingLogins] =useState();
 



const [name, setName] = useState()
const [mobile, setPhone] = useState()
const [gram, setCityGram] = useState()
const [thana, setThana] = useState()
const [zip, setziPcode] = useState()

const datal={
  name:name,
  mobile:mobile,
  gram:gram,
  thana:thana,
  zip:zip,
  id:localStorage.getItem('id')
}

const okinfo=()=>{


  setDivshow(<Spin
    indicator={
      <LoadingOutlined
        style={{
          fontSize: 24,
        }}
        spin
      />
    }
  /> );

  
  Larvelapi.post('Payment_optons_post',datal)

.then(res =>{
 


  setDivshow(<VerifiedIcon color='success'/>)
})
.catch(error=>{


  setDivshow(<NewReleasesIcon className=' text-danger' color='danger'/>)

})




}





return (
<>





<div className='p-4'>
  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label for="validationDefault01">{ <p className='text-danger'> {'Name  (**)'}</p>}</label>
      <input type="text" class="form-control"  onChange={(e)=>setName(e.target.value)}  maxLength={15} id="validationDefault01" placeholder="Enter name"  required />
    </div>

    
    <div class="col-md-4 mb-3">
    <label for="validationDefault01">{ <p className='text-danger'> {'phone  (**)'}</p>}</label>
      <input type="text" class="form-control" onChange={(e)=>setPhone(e.target.value)}  maxLength={11} id="validationDefault02" placeholder="Phone number : 01784-22"  required />
    </div>

    {/* <div class="col-md-4 mb-3">
      <label for="validationDefaultUsername">Username</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroupPrepend2">@</span>
        </div>
        <input type="text" class="form-control" id="validationDefaultUsername" placeholder="Username" aria-describedby="inputGroupPrepend2" required />
      </div>
    </div> */}


  </div>
  <div class="form-row">
    <div class="col-md-6 mb-3">
    
      <label for="validationDefault01">{ <p className='text-danger'> {'City  (**)'}</p>}</label>
      <input type="text" maxLength={20} onChange={(e)=>setCityGram(e.target.value)}   class="form-control" id="validationDefault03" placeholder="City" required />
    </div>
    <div class="col-md-3 mb-3">
    
      <label for="validationDefault01">{ <p className='text-success'> {'State  (**)'}</p>}</label>
      <input type="text" class="form-control" onChange={(e)=>setThana(e.target.value)}  id="validationDefault04" placeholder="State" required />
    </div>
    <div class="col-md-3 mb-3">
   
      <label for="validationDefault01">{ <p className='text-success'> {'Zip code  (**)'}</p>}</label>
      <input type="text" maxLength={8} onChange={(e)=>setziPcode(e.target.value)}  class="form-control" id="validationDefault05" placeholder="Zip code" required />
    </div>
  </div>
  <div class="form-group">


   {/* <Button  loadingPosition="start">
        Start
      </Button> */}

<Button  onClick={okinfo}>info submit {divshow} </Button>
    {/* <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="invalidCheck2" required />
      <label class="form-check-label" for="invalidCheck2">
        Agree to terms and conditions
      </label>
    </div> */}


  </div>

</div>



</>


)

}




const [degabeture, setValueDisabled] = useState(true);

const [cashonedelevery, setValue22] = useState('ক্যাশ অন ডেলিভারি');
const onChange222 = (e) => {
  console.log('radio checked', e.target.value);
  setValue22(e.target.value);


  let id=e.target.value;


  if(id=='প্রোডাক্ট গিফট'){
    setValueDisabled(false);
  }else{
  
    setValueDisabled(true);
  
  }



};














const [bkash, setValuebkash] = useState('');
const onChabkashornokt = (e) => {
  console.log('radio checked', e.target.value);
  setValuebkash(e.target.value);




};




const [phone, setphoneNumber] = useState()
const [trans, settranId] = useState()
const datall={
bkash:bkash,
type:cashonedelevery,
phone:phone,
trans:trans,
id:localStorage.getItem('id'),



}




const [divshow, setDivshow] = useState(<KeyboardBackspaceIcon />);


const Payment_optons_post=()=>{



  setDivshow(<Spin
    indicator={
      <LoadingOutlined
        style={{
          fontSize: 24,
        }}
        spin
      />
    }
  /> );



  Larvelapi.post('Payment_optons_post',datall)

.then(res =>{
  
  setDivshow(<VerifiedIcon color='success'/>)
})
.catch(error=>{


  setDivshow(<NewReleasesIcon className=' text-danger' color='danger'/>)

})

}






    return (
        <>


<Divider
          style={{
            color: '#fff',
            borderColor: '#1677ff',
            borderStyle: 'dashed',
          }}
        >
          Select Option 

        </Divider>





<Radio.Group onChange={onChange} value={value4545}  defaultValue="4"  buttonStyle="solid">
      <Radio.Button value="payment method">payment</Radio.Button>
      <Radio.Button  disabled={degabeture}  value="product gift">product gift info</Radio.Button>
   
    </Radio.Group>




        <Divider
          style={{
            color: '#fff',
            borderColor: '#1677ff',
            borderStyle: 'dashed',
          }}
        >
         {value4545}

        </Divider>







<>




{value4545=='product gift' ?  



  vlauerados()
:

<>


<Radio.Group  onChange={onChange222} name="radiogroup" className='text-white p-2 radio  m-2' defaultValue={'ক্যাশ অন ডেলিভারি'} value={cashonedelevery}>
    <Radio className='text-white' value={'ক্যাশ অন ডেলিভারি'} checked>ক্যাশ অন ডেলিভারি</Radio>

    <Radio className='text-white' value={'প্রোডাক্ট গিফট'}> প্রোডাক্ট গিফট </Radio>

    <Radio disabled={true} className='text-white' value={'ক্যাশ পেমেন্ট'}> ক্যাশ পেমেন্ট </Radio>

  </Radio.Group>


{/* 
    <Radio.Group onChange={onChange222} value={value}>

    <Radio  id='idid' value={'cash on delivery'}></Radio>
      <Radio id='idid' value={'cash on delivery'}>ক্যাশ অন ডেলিভারি</Radio>
    </Radio.Group> */}


<>






{cashonedelevery=='ক্যাশ অন ডেলিভারি' ?


<>
<h6 className='p-2 m-2'>

প্রোডাক্ট পাওয়ার পর ডেলিভারী ম্যানকে  পেমেন্ট করতে হবে ।
</h6>



</>

:

<>


<br/>

<h6 className='p-2 m-2'>

 অন্য কাউকে প্রোডাক্ট গিফট করলেই বিকাশে অথবা নগদ এর মাধ্যমে পেমেন্ট করতে হবে । {'( ক্যাশ  ডেলিভারি )'}

</h6>

<h2 className='h5'>
  01784-228999
</h2>


<div className='p-2 m-2'>



<Radio.Group  onChange={onChabkashornokt} name="radiogroup" className='text-white p-2 radio  m-2' value={bkash}>
    <Radio className='text-white' value={'বিকাশ'} checked>বিকাশ</Radio>
    <Radio className='text-white' value={'নগত'}>নগত</Radio>

  </Radio.Group>




<form class="form-inline" >



{bkash}

  <div class="form-group mb-2">

    {/* <input type="text" readonly class="form-control-plaintext" id="staticEmail2" value="email@example.com" /> */}
  </div>


  <div class="form-group mx-sm-3 mb-2">
    <label for="inputPassword2" class="sr-only"> Trans ID :  </label>
    <input type="text" class="form-control" id="inputPassword2"  onChange={(e)=>settranId(e.target.value)} placeholder="Trans ID - input id" />
  </div>
  <div class="form-group mx-sm-3 mb-2">
    <label for="inputPassword2" class="sr-only">payment phone number </label>
    <input type="name" onChange={(e)=>setphoneNumber(e.target.value)}  required  maxLength={11} class="form-control" id="inputPassword2" placeholder="payment namber" />
  </div>

</form>
  <button type="submit"  onClick={Payment_optons_post}  required class=" btn bg-info">Confirm identity {divshow} </button>



</div>

<br/>


</>






}







</>

{/* <h3>ক্যাশ অন ডেলিভারি</h3> */}

</>


}
















</>









        </>
    )
}


const steps = [
    {
      title: <p className='text-white'>Order list</p>,
      content: <Orldritetable />,
    },
    {
      title:<p className='text-white'>payment option</p>, 
    
      content: <Orldrtime />,
    },
    {
      title: <p className='text-white'>Order Done </p>,
      content: <Orldrtime3 />,
    },
  ];
  




const App = () => {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));



  const contentStyle = {
    
    textAlign: 'center',
    color: '#fff',
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };












  // this order comptel option

const alerts=()=>{
  message.success('Processing complete!')
}

// this is a order confirm done order list
// this is a order confirm done order list
// this is a order confirm done order list
// this is a order confirm done order list
// this is a order confirm done order list
// this is a order confirm done order list
// this is a order confirm done order list
// this is a order confirm done order list
// this is a order confirm done order list
// this is a order confirm done order list
// this is a order confirm done order list
// this is a order confirm done order list



const [logings, setLoads] = useState(


  <Spin tip="Order Prosesing...." className='text-center' size="large">
  <div   className="content text-center" />
</Spin>

)









const usernviates=useNavigate();


const completeorders=()=>{





  setOpen(true);


  setTimeout(() => {

    setOpen(false);
  
  }, 8000);



 

let id =localStorage.getItem('id');

  UrlnodeJsapi.get(`order_confim_done/${id}`)
  .then(res=>{

// alert(res.data);







if(res.data=='complete'){


  setTimeout(() => {

    setLoads(
      <>



      <CheckCircleIcon className='text-success' />
       

      <p className='text-success'>Order prosess complete </p>
    {usernviates('/')}
      </>
      
        );


  }, 5000);



}else{



  setTimeout(() => {


  setLoads(
    <>

    <WarningIcon className='text-warning' />

    <p className='text-warning'>network error </p>
</>
     
   );

      
}, 2000);

  


}




  })
  .then(error=>{



    setTimeout(() => {


    
    

        setLoads(
<>

  
<CheckCircleIcon className='text-success' />
       

       <p className='text-success'>Order Prosesing..... complete </p>
</>
         
        
          );
    
         
      
    
          
    }, 3000);

   
console.log('====================================');

console.log('====================================');

  })








 
}






const [open, setOpen] = React.useState(false);

const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};








  return (
    <>

    <div className='p-4'>



   
      <Steps current={current} items={items} style={{color:'aqua'}} />
      <div className='text-white' style={contentStyle}>   {steps[current].content}</div>
      <div
        style={{
          marginTop: 24,
        }}
      >
        {current < steps.length - 1 && (

          <>
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>


          <Button
            style={{
              margin: '0 8px',
              color:'blue',
            }}
            className='bg-dark text-info btn'
            onClick={() => usernviates('/')}
          >
            home 
          </Button>

          </>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={() =>completeorders()}>
           Order  Done
          </Button>
        )}
        {current > 0 && (

          <>

    
          <Button
            style={{
              margin: '0 8px',
            }}
            onClick={() => prev()}
          >
            Previous
          </Button>


   

          </>

        )}
      </div>

























<>




<React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Slide in alert dialog
      </Button> */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>


          <DialogContentText id="alert-dialog-slide-description" className='p-4'>




 {logings}

            {/* Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running. */}
          </DialogContentText>
          <Flex gap="large"  className='p-4'   vertical>

  


    





<h6 className='p-1'> 
<button  className='btn'> <a href='/'></a>   </button></h6>

</Flex>

        </DialogContent>











        {/* <DialogActions>
  
        
        </DialogActions> */}
      </Dialog>
    </React.Fragment>
</>



      </div>


    </>
  );
};
export default App;